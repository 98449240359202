import React from "react";
import PaginatedTable from '../components/paginatedTable'

import SectorFilterDialog from "../components/sectorFilterDialog";
import { Button } from "@mui/material";
import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import klarna_logo from '../assets/startups/klarna-logo.png'
import NewTeamUserDialog from "../components/newTeamUserDialog";

const items = [
  { id: 1, firstname: 'Jon', lastname: 'Jones', role: 'Admin', email: 'jon.jones@gmail.com', status: 'Blocked', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 2, firstname: 'Cersei', lastname: 'Jones', role: 'Admin', email: 'cersei.jones@gmail.com', status: 'Blocked', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 3, firstname: 'Jaime', lastname: 'Jones', role: 'Admin', email: 'jaime.jones@gmail.com', status: 'Blocked', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 4, firstname: 'Arya', lastname: 'Jones', role: 'Admin', email: 'arya.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 5, firstname: 'Daenerys', lastname: 'Jones', role: 'Admin', email: 'daenerys.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 6, firstname: 'Camille', lastname: 'Jones', role: 'Admin', email: 'camille.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 7, firstname: 'Ferrara', lastname: 'Jones', role: 'Admin', email: 'ferrara.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 8, firstname: 'Rossini', lastname: 'Jones', role: 'Admin', email: 'rossini.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 9, firstname: 'Harvey', lastname: 'Jones', role: 'Admin', email: 'harvey.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 10, firstname: 'Jon', lastname: 'Jones', role: 'Admin', email: 'jon.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 11, firstname: 'Cersei', lastname: 'Jones', role: 'Admin', email: 'cersei.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 12, firstname: 'Jaime', lastname: 'Jones', role: 'Admin', email: 'jaime.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 13, firstname: 'Arya', lastname: 'Jones', role: 'Admin', email: 'arya.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 14, firstname: 'Daenerys', lastname: 'Jones', role: 'Admin', email: 'daenerys.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 15, firstname: 'Camille', lastname: 'Jones', role: 'Admin', email: 'camille.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 16, firstname: 'Ferrara', lastname: 'Jones', role: 'Admin', email: 'ferrara.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 17, firstname: 'Rossini', lastname: 'Jones', role: 'Admin', email: 'rossini.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
  { id: 18, firstname: 'Harvey', lastname: 'Jones', role: 'Admin', email: 'harvey.jones@gmail.com', status: 'Active', country: 'Italy', city: 'Milan', logo: klarna_logo },
];

function LogoColumn(params) {
  return (
    <div className="flex h-full items-center">
      <div className="flex items-center justify-center">
        <img src={params.value} alt="User logo" className="w-10 h-10 rounded-full object-cover"/>
      </div>
    </div>
  )
}

export default function RetailhubTeam() {
  const [rows, setRows] = React.useState(items);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const header = [
    { 
      field: 'logo', 
      headerName: 'Logo', 
      width: 100,
      sortable: false,
      renderCell: LogoColumn
    },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'firstname', headerName: 'Firstname', width: 130 },
    { field: 'lastname', headerName: 'Lastname', width: 130 },
    { field: 'role', headerName: 'Role', width: 130 },
    { field: 'status', headerName: 'Status', width: 120 },
    { field: 'country', headerName: 'Country', width: 120},
    { field: 'city', headerName: 'City', width: 100},
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
  
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    }
  ];

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (!!editedRow && editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-[100vh]">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full relative">
          
          <div className="flex justify-between items-center w-full">
            <h1 className="text-6xl font-medium">Manage RetailHub Team</h1>
            <NewTeamUserDialog />
          </div>
          <div className="flex flex-wrap gap-4">
            <SectorFilterDialog />
            <Button 
              variant="outlined"
              sx={{
                fontFamily: "Urbanist, sans-serif", 
                border: "1px solid rgb(255, 255, 255, .3)", 
                color: "rgb(255, 255, 255, .3)"
              }}
            >
              Status
            </Button>
            <Button 
              variant="outlined"
              sx={{
                fontFamily: "Urbanist, sans-serif", 
                border: "1px solid rgb(255, 255, 255, .3)", 
                color: "rgb(255, 255, 255, .3)"
              }}
            >
              Location
            </Button>
            <Button 
              variant="outlined"
              sx={{
                fontFamily: "Urbanist, sans-serif", 
                border: "1px solid rgb(255, 255, 255, .3)", 
                color: "rgb(255, 255, 255, .3)"
              }}
            >
              Year
            </Button>
            <Button 
              variant="outlined"
              sx={{
                fontFamily: "Urbanist, sans-serif", 
                border: "1px solid rgb(255, 255, 255, .3)", 
                color: "rgb(255, 255, 255, .3)"
              }}
            >
              Tags
            </Button>
            <Button 
              variant="outlined"
              sx={{
                fontFamily: "Urbanist, sans-serif", 
                border: "1px solid rgb(255, 255, 255, .3)", 
                color: "rgb(255, 255, 255, .3)"
              }}
            >
              Available to chat
            </Button>
            <Button 
              variant="outlined"
              sx={{
                fontFamily: "Urbanist, sans-serif", 
                border: "1px solid rgb(255, 255, 255, .3)", 
                color: "rgb(255, 255, 255, .3)"
              }}
            >
              Sort by
            </Button>
          </div>
          <PaginatedTable header={header} items={items} />
        </div>
      </div>
    </div>
  )
}
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import StartupsCard from "../components/startupCard";
import SectorFilterDialog from "../components/sectorFilterDialog";
import {
  Button,
  Pagination,
  MenuItem,
  Select,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  FormControlLabel
} from "@mui/material";

import SkeletonCard from "../components/skeleton/skeletonCard";

function Startups() {
  const [startupsData, setStartupsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage] = useState(9);
  const [orderDir, setOrderDir] = useState("desc");
  const [orderBy] = useState("added");
  const [totalResults, setTotalResults] = useState(0);
  const [filters, setFilters] = useState({});

  // Tutti i filtri li gestiamo come stringhe
  const [selectedFilters, setSelectedFilters] = useState({
    chat: "",
    business_models: [],
    stages: [],
    company_types: [],
    target_markets: [],
    tags: [],
    order_by: "",
    order_dir: "",
    countries: [],
    year_min: "",
    year_max: "",
    categories: []
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [resetSignal, setResetSignal] = useState(false);
  const [dialogPlaceholder, setDialogPlaceholder] = useState("");
  const [componentKey, setComponentKey] = useState(0);

  // Nuovo stato per il client (se presente)
  const [clientName, setClientName] = useState("");

  // Funzione per convertire i parametri della query string in un oggetto
  const parseQueryParams = (search) => {
    const params = new URLSearchParams(search);
    const parsedParams = {};
    for (const [key, value] of params.entries()) {
      parsedParams[key] = value;
    }
    return parsedParams;
  };

  // Funzione per ottenere i dati dei filtri
  const fetchFilterData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/values/innovation-filters`
      );
      // Se vuoi, puoi già salvare i filtri come stringhe, ma se arrivano come stringhe va bene così.    
      setFilters({
        ...response.data.data,
        countries: response.data.data.countries?.sort((a, b) => a.name.localeCompare(b.name))
      });
    } catch (err) {
      console.error("Errore nel caricamento dei filtri", err);
    }
  };

  // Funzione per ottenere i dati delle startup
  const fetchData = async (page) => {
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token non trovato. Effettua il login.");
      }

      const queryParams = new URLSearchParams();
      queryParams.append("page", page);
      queryParams.append("per_page", perPage);
      queryParams.append("order_dir", orderDir);
      queryParams.append("order_by", orderBy);

      // Aggiungo i filtri
      if (selectedFilters.chat !== "") {
        queryParams.append("chat", selectedFilters.chat);
      }
      if (selectedFilters.countries.length > 0) {
        selectedFilters.countries.forEach((countryId) => {
          queryParams.append("country", countryId);
        });
      }
      if (selectedFilters.year_min) {
        queryParams.append("year_min", selectedFilters.year_min);
      }
      if (selectedFilters.year_max) {
        queryParams.append("year_max", selectedFilters.year_max);
      }
      if (selectedFilters.business_models.length > 0) {
        queryParams.append(
          "business_model",
          selectedFilters.business_models[0]
        );
      }
      if (selectedFilters.stages.length > 0) {
        queryParams.append("status", selectedFilters.stages[0]);
      }
      if (selectedFilters.company_types.length > 0) {
        selectedFilters.company_types.forEach((id) => {
          queryParams.append("type", id);
        });
      }
      if (selectedFilters.target_markets.length > 0) {
        selectedFilters.target_markets.forEach((id) => {
          queryParams.append("target_markets[]", id);
        });
      }

      // Aggiungi i tag
      if (selectedFilters.tags.length > 0) {
        selectedFilters.tags.forEach((tagId) => {
          queryParams.append("tags[]", tagId);
        });
      }

      // Aggiungi le categorie
      if (selectedFilters.categories.length > 0) {
        selectedFilters.categories.forEach((id) => {
          queryParams.append("categories[]", id);
        });
      }

      // Aggiungi i parametri dalla query string attuale (se vuoi preservarne altri)
      const queryParamsFromURL = parseQueryParams(location.search);
      Object.entries(queryParamsFromURL).forEach(([key, value]) => {
        if (!queryParams.has(key)) {
          queryParams.append(key, value);
        }
      });

      const url = `${process.env.REACT_APP_API_URL}/innovations?${queryParams.toString()}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const data = response.data;
      setStartupsData(data.data);
      setTotalResults(data.total);
      setTotalPages(parseInt(data.last_page, 10));
    } catch (err) {
      setError(err.message || "Errore nel caricamento dei dati");
    } finally {
      setLoading(false);
    }
  };

  // Funzione per resettare i filtri
  const resetFilters = () => {
    setSelectedFilters({
      chat: "",
      business_models: [],
      stages: [],
      company_types: [],
      target_markets: [],
      tags: [],
      order_by: "",
      order_dir: "",
      countries: [],
      year_min: "",
      year_max: "",
      categories: []
    });
    setOrderDir("desc");
    setPage(1);
    setResetSignal(!resetSignal);
    setDialogPlaceholder("Sectors");
    setClientName(""); // Reset del clientName
    navigate("/startups"); // Rimuove tutti i param dalla query string

    // Forza il re-render
    setComponentKey((prevKey) => prevKey + 1);
  };

  // useEffect per inizializzare i filtri da URL
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    // 1) Categorie
    const categories = urlParams.getAll("categories[]");
    if (categories.length > 0) {
      setSelectedFilters((prev) => ({
        ...prev,
        categories
      }));
    } else {
      setSelectedFilters((prev) => ({
        ...prev,
        categories: []
      }));
    }

    // 2) Tags
    const tags = urlParams.getAll("tags[]");
    if (tags.length > 0) {
      setSelectedFilters((prev) => ({
        ...prev,
        tags
      }));
    }

    // 3) Name (placeholder)
    const name = urlParams.getAll("name[]");
    if (name.length > 0) {
      setDialogPlaceholder(name[0]);
    } else {
      setDialogPlaceholder("Sectors");
    }

    // 4) Client
    const client = urlParams.get("client");
    if (client) {
      setClientName(client);
    } else {
      setClientName("");
    }
  }, [location.search]);

  // useEffect per chiamare fetchData
  useEffect(() => {
    fetchData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selectedFilters, orderDir, location.search]);

  // useEffect per caricare i filtri una sola volta
  useEffect(() => {
    fetchFilterData();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleFilterChange = (filterName, selectedValues) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues
    }));
  };

  const handleOrderChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "reset") {
      resetFilters();
    } else {
      setOrderDir(selectedValue);
    }
  };

  // Selezione categoria singola
  const handleCategorySelect = (categoryId) => {
    // Se categoryId è una stringa, va bene. 
    // Se fosse un numero, potresti fare: categoryId.toString()
    setSelectedFilters((prev) => ({
      ...prev,
      categories: [categoryId]
    }));
  };

  const listStartups = startupsData.map((s) => (
    <StartupsCard
      key={s.id}
      id={s.id}
      name={s.name}
      year={s.founding_year}
      description={
        s.description
          ? s.description.replace(/<\/?[^>]+(>|$)/g, "")
          : "No description available"
      }
      logo={s.logo_url || `${process.env.REACT_APP_BASE_URL}/default-logo.png`}
      countryCode={s.country?.iso || "US"}
      tags={
        s.taxonomies?.tags?.length > 0
          ? s.taxonomies.tags.map((tag, index) => ({
              id: index,
              label: tag.name || "N/A",
              variant: "outlined"
            }))
          : [{ id: 0, label: "No tags", variant: "outlined" }]
      }
      link={`/startups/startup/${s.id}`}
      isBookmarked={s.is_bookmarked}
    />
  ));

  return (
    <div
      key={componentKey}
      className="bg-black p-5 h-full min-h-[100vh] flex flex-col gap-10"
    >

      {/* Filtri e header */}
      <div className="flex flex-col gap-2">
        <h1 className="text-6xl font-medium">Startups</h1>

        {/* Qui mostri il client se presente */}
        <p>
          {loading
            ? "Loading results..."
            : `${clientName ? clientName + " - " : ""}Showing ${totalResults} results`
          }
        </p>

        <div className="flex flex-wrap gap-4">
          <SectorFilterDialog
            onCategorySelect={handleCategorySelect}
            resetSignal={resetSignal}
            placeholder={dialogPlaceholder}
          />

          <FilterSelect
            label="Location"
            options={[{ id: "", name: "Reset" }, ...(filters.countries || [])]}
            selected={selectedFilters.countries}
            onChange={(selected) => handleFilterChange("countries", selected)}
          />

          <FilterSelect
            label="Type"
            options={[
              { id: "", name: "Reset" },
              ...(filters.company_types || [])
            ]}
            selected={selectedFilters.company_types}
            onChange={(selected) => handleFilterChange("company_types", selected)}
          />

          <FilterSelect
            label="Target Markets"
            options={[
              { id: "", name: "Reset" },
              ...(filters.target_markets || [])
            ]}
            selected={selectedFilters.target_markets}
            onChange={(selected) =>
              handleFilterChange("target_markets", selected)
            }
            width={"170px"}
          />

          <FilterSelect
            label="Business Models"
            options={[
              { id: "", name: "Reset" },
              ...(filters.business_models || [])
            ]}
            selected={selectedFilters.business_models}
            onChange={(selected) =>
              handleFilterChange("business_models", selected)
            }
            width={"170px"}
          />

          <FilterSelect
            label="Status"
            options={[{ id: "", name: "Reset" }, ...(filters.stages || [])]}
            selected={selectedFilters.stages}
            onChange={(selected) => handleFilterChange("stages", selected)}
          />

          {/* Filtro Tags */}
          <FilterSelect
            label="Tags"
            options={[{ id: "", name: "Reset" }, ...(filters.tags || [])]}
            selected={selectedFilters.tags}
            onChange={(selected) => handleFilterChange("tags", selected)}
          />

          <FormControl sx={{ minWidth: 120 }}>
            <Select value={orderDir} onChange={handleOrderChange}>
              <MenuItem value="asc">Older</MenuItem>
              <MenuItem value="desc">Recent</MenuItem>
              <MenuItem value="reset">Reset</MenuItem>
            </Select>
          </FormControl>

          <Button variant="outlined" onClick={resetFilters} sx={{ ...buttonStyles }}>
            Reset All Filters
          </Button>
        </div>
      </div>

      <div className="flex flex-wrap gap-4">
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedFilters.chat === "true"}
              onChange={(event) =>
                handleFilterChange("chat", event.target.checked ? "true" : "")
              }
              sx={{
                color: "#C5FF55",
                "&.Mui-checked": { color: "#C5FF55" },
                "& .MuiSvgIcon-root": { fontFamily: "Urbanist, sans-serif" },
                "& .MuiCheckbox-root": { border: "1px solid #C5FF55" }
              }}
            />
          }
          label="Show Startups Available to chat only"
        />
      </div>

      <div
        className="grid gap-3"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(1, 1fr)",
          gridGap: "15px",
          alignItems: "stretch"
        }}
      >
        <style>
          {`
            @media (min-width: 768px) {
              div.grid {
                grid-template-columns: repeat(3, 1fr) !important;
              }
              div.grid > div {
                display: flex !important;
                flex-direction: column;
              }
            }
          `}
        </style>
        {loading ? (
          [...Array(9)].map((_, index) => <SkeletonCard key={index} />)
        ) : error ? (
          <p style={{ color: "red" }}>{error}</p>
        ) : startupsData.length > 0 ? (
          listStartups
        ) : (
          <p>No startups found</p>
        )}
      </div>

      <div className="flex justify-center mt-6">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          className="pagination"
        />
      </div>
    </div>
  );
}

/**
 * FilterSelect
 * Componente generico per creare menu di selezione multipla.
 */
const FilterSelect = ({
  label,
  options,
  selected,
  onChange,
  multiple = true,
  width
}) => {
  return (
    <FormControl sx={{ minWidth: 120, width: width || "auto" }}>
      <InputLabel sx={{ fontFamily: "Urbanist, sans-serif" }}>{label}</InputLabel>
      <Select
        multiple={multiple}
        value={selected || (multiple ? [] : "")}
        onChange={(e) => onChange(e.target.value)}
        renderValue={(selected) => {
          if (multiple) {
            return selected
              .map(
                (sel) => options.find((option) => option.id === sel)?.name || ""
              )
              .join(", ");
          } else {
            return (
              options.find((option) => option.id === selected)?.name || ""
            );
          }
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {/* Se è multiple, mostra la checkbox. 
                Confronto: selected.includes(option.id) (entrambi stringhe). */}
            {multiple && (
              <Checkbox checked={selected.includes(option.id)} />
            )}
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const buttonStyles = {
  fontFamily: "Urbanist, sans-serif",
  border: "1px solid rgb(255, 255, 255, .3)",
  color: "rgb(255, 255, 255, .3)"
};

export default Startups;

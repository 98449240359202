import * as React from 'react';
import { Dialog, DialogActions, DialogContent} from '@mui/material';
import StandardButton from './standardButton';
import { Delete } from '@mui/icons-material';
import axios from 'axios';

export default function DeleteSurveyAnswersDialog({interviewees, surveyId, onSurveyUpdate}) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const deleteAll = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
      const url = `${process.env.REACT_APP_API_URL}/advisory/surveys/${surveyId}`;

      await axios.delete(url, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (onSurveyUpdate) {
        onSurveyUpdate(); // Aggiorna la lista delle survey
      }
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setOpen(false);
    }
  };

  const deleteIntervieweeAnswer = async (id) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
      const url = `${process.env.REACT_APP_API_URL}/advisory/interviewee-survey`;

      await axios.delete(url, {
        data: { interviewee_survey_ids: [Number(id)] },
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
      });

      if (onSurveyUpdate) {
        onSurveyUpdate(); // Aggiorna la lista delle survey
      }
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <StandardButton 
        text="Delete answers"
        width="350px" 
        type="error"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: "16px !important",
            overflow: 'hidden',
            backgroundColor: "#191919"
          }
        }}
      >
        <DialogContent sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919"}}>
          <div className='flex flex-col gap-10 justify-center items-center w-full'>
            <div className='flex flex-col gap-2 justify-center items-center w-full'>
              <h1 className="text-xl font-bold w-full">Are you sure you want to delete the submission and all related results?</h1>
              <span className="text-white w-full">This operation will be irreversible.</span>
            </div>
            <div className='flex flex-col gap-1 w-full'>
              {
                !!interviewees && 
                interviewees.map(na =>
                  <div key={na.interviewee_id} className='flex w-full justify-between items-center text-white py-2'>
                    <span>{na.first_name} {na.last_name}</span>
                    <Delete onClick={() => {deleteIntervieweeAnswer(na.interviewee_survey_id)}} sx={{cursor: 'pointer'}}/>
                  </div>
                )
              }
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919", paddingTop: "40px", justifyContent: 'center', gap: '50px'}}>
          <StandardButton variant='outlined' onClick={handleClose} text="Cancel" width={"300px"} type={"secondary"}/>
          <StandardButton onClick={() => {deleteAll()}} type={'error'} text="Delete all" width={"300px"}/>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import StandardButton from "./standardButton";
import axios from "axios";

export default function AddStartupComparison({ open, onClose, onConfirm, selectedInnovationId }) {
  const [startupList, setStartupList] = useState([]);
  const [selectedStartups, setSelectedStartups] = useState([]);
  const [comparisonStatus, setComparisonStatus] = useState({});
  const [error, setError] = useState(null);

  const MAX_SELECTION_LIMIT = 2;

  useEffect(() => {
    if (!open) return;

    const fetchStartupData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Authentication token not found");

        const comparisonResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/advisory/consultant/compare_to_innovation`,
          {
            params: { innovation_id: selectedInnovationId },
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const comparisonIds = comparisonResponse.data.data.map((item) => item.innovation_id);

        const startupsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/advisory/consultant/innovation_list`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const fullList = startupsResponse.data.data;

        const filteredList = comparisonIds
          .map((id) => fullList.find((startup) => startup.innovation_id === id))
          .filter(Boolean);

        const deduplicatedList = [
          ...filteredList,
          ...fullList.filter((startup) => !comparisonIds.includes(startup.innovation_id)),
        ];

        setStartupList(deduplicatedList);

        const initialStatus = {};
        comparisonIds.forEach((id) => {
          initialStatus[id] = { compare: true };
        });
        setComparisonStatus(initialStatus);
      } catch (err) {
        setError(err.message || "Failed to fetch startup data");
      }
    };

    fetchStartupData();
  }, [open, selectedInnovationId]);

  const handleCompareClick = async (relatedInnovationId, compare) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Authentication token not found");

      const payload = {
        innovation_id: selectedInnovationId,
        related_innovation_id: relatedInnovationId,
        compare,
      };

      await axios.post(`${process.env.REACT_APP_API_URL}/advisory/consultant/compare_to_innovation`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setComparisonStatus((prevState) => ({
        ...prevState,
        [relatedInnovationId]: { compare },
      }));

      if (compare) {
        if (selectedStartups.length < MAX_SELECTION_LIMIT) {
          setSelectedStartups((prev) => [...prev, relatedInnovationId]);
        } else {
          alert(`You can select a maximum of ${MAX_SELECTION_LIMIT} startups.`);
        }
      } else {
        setSelectedStartups((prev) => prev.filter((id) => id !== relatedInnovationId));
      }
    } catch (err) {
      console.error("Error while updating comparison:", err);
    }
  };

  const handleConfirm = () => {
    onConfirm(selectedStartups); // Passa le startup selezionate al componente padre
    onClose(); // Chiudi la modale
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "16px !important",
          overflow: "hidden",
          backgroundColor: "#191919",
        },
      }}
    >
      <DialogContent sx={{ fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919" }}>
        <Typography variant="h4" component="h1" sx={{ color: "white", textAlign: "center" }}>
          Add a New Startup
        </Typography>
        <Typography variant="body1" sx={{ color: "gray", textAlign: "center" }}>
          Select up to {MAX_SELECTION_LIMIT} startups for comparison.
        </Typography>

        {error && <Typography color="error">{error}</Typography>}

        <List sx={{ width: "100%", maxHeight: "400px", overflowY: "auto" }}>
          {startupList
            .filter((startup) => startup.innovation_id !== selectedInnovationId) // Filtra il selectedInnovationId
            .map((startup) => {
              const status = comparisonStatus[startup.innovation_id] || {};
              const isSelected = status.compare;
            
              return (
                <ListItem
                  key={startup.innovation_id}
                  sx={{
                    backgroundColor: isSelected ? "#C5FF55" : "#333",
                    color: isSelected ? "#000" : "white",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ListItemText primary={startup.innovation_title} sx={{ color: isSelected ? "#000" : "white" }} />
                  <div>
                    <IconButton
                      onClick={() => handleCompareClick(startup.innovation_id, true)}
                      disabled={status.compare || selectedStartups.length >= MAX_SELECTION_LIMIT}
                      sx={{
                        color: status.compare ? "gray" : isSelected ? "#000" : "#C5FF55",
                      }}
                    >
                      <Add />
                    </IconButton>
                    <IconButton
                      onClick={() => handleCompareClick(startup.innovation_id, false)}
                      disabled={!status.compare}
                      sx={{
                        color: !status.compare ? "gray" : isSelected ? "#000" : "#C5FF55",
                      }}
                    >
                      <Remove />
                    </IconButton>
                  </div>
                </ListItem>
              );
            })}
        </List>
      </DialogContent>

      <DialogActions
        sx={{
          fontFamily: "Urbanist, sans-serif",
          backgroundColor: "#191919",
          paddingTop: "40px",
          justifyContent: "center",
          gap: "50px",
        }}
      >
        <StandardButton
          variant="outlined"
          onClick={onClose}
          text="Cancel"
          width="300px"
          type="secondary"
        />
        <StandardButton
          onClick={handleConfirm}
          text="Confirm"
          width="300px"
        />
      </DialogActions>
    </Dialog>
  );
}

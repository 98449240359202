import React, { useState, useContext, useEffect } from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useLocation, Link } from 'react-router-dom'; // Import Link
import LogoRH from '../assets/images/LogoRH.png';
import bookmarks from '../assets/images/bookmarks.png';
import email from '../assets/images/email.png';
import bell from '../assets/images/bell.png';
import user_fake from '../assets/images/avatar.png';
import { NotificationContext } from "../index";
import AccountMenu from './accountMenu';


function Header() {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Gestione Notifiche Chat
  const { notifications, setNotifications, badgeVisible, setBadgeVisible } = useContext(NotificationContext);
  const unreadCount = notifications.filter((n) => !n.isRead).length;

  // Aggiorna la visibilità del badge quando cambiano le notifiche
  useEffect(() => {
    setBadgeVisible(unreadCount > 0);
  }, [unreadCount, setBadgeVisible]);

  // Gestione del clic sulla campanella
  const handleBellClick = () => {
    setBadgeVisible(false); // Nascondi il badge
    // Marca tutte le notifiche come lette
    const updatedNotifications = notifications.map((n) => ({ ...n, isRead: true }));
    setNotifications(updatedNotifications);
  };

  // Handle input change
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle search submission
  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      if (searchQuery.trim()) {
        navigate(`/startups?name=${encodeURIComponent(searchQuery)}`);
      }
    }
  };

  if (location.pathname === '/lander-survey' || location.pathname === '/register') {
    return null;
  }

  return (
    <Box
      component="header"
      sx={{
        display: 'flex',
        backgroundColor: '#000',
        padding: 2,
      }}
    >
      <Box sx={{ flexGrow: 1, padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <a href="/">
          <img src={LogoRH} alt="logo rh" style={{ width: '50px' }} />
        </a>
      </Box>

      <Box sx={{ flexGrow: 2, padding: 1, alignItems: 'center', justifyContent: 'center', display: { xs: 'none', sm: 'flex' }, }}>
        <ul className="nav-links">
          <li><a href="/startups">Scout startups</a></li>
          <li><a className="btn-link" href="/survey/chat">AI Navigator &#8599;</a></li>
          <li><a className="btn-link" href="/advisory">Advisory Program &#8599;</a></li>
        </ul>
      </Box>

      <Box sx={{ flexGrow: 4, padding: 1, alignItems: 'center', display: { xs: 'none', sm: 'flex' }, }}>
        <TextField
          variant="outlined"
          placeholder="Search for startups"
          fullWidth
          value={searchQuery}
          onChange={handleInputChange}
          onKeyDown={handleSearchSubmit}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon
                  sx={{ color: '#818181', cursor: 'pointer' }}
                  onClick={handleSearchSubmit}
                />
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#818181',
                fontFamily: 'Urbanist, sans-serif',
              },
              '&:hover fieldset': {
                borderColor: '#818181',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#818181',
              },
            },
            '& .MuiOutlinedInput-input': {
              color: '#818181',
              fontFamily: 'Urbanist, sans-serif',
            },
            '& .MuiInputLabel-root': {
              color: '#818181',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#818181',
            },
          }}
        />
      </Box>

      <Box sx={{ flexGrow: 1, padding: 1, alignItems: 'center', justifyContent: 'flex-end', display: { xs: 'none', sm: 'flex' } }}>
        <ul className="nav-links">
          <a href="/bookmarks-startups">
            <img src={bookmarks} alt="bookmarks" />
          </a>
          <a href="/chats">
            <img src={email} alt="email" />
          </a>
          <div style={{ position: 'relative', cursor: 'pointer' }} onClick={handleBellClick}>
            <Link to={localStorage.getItem('innovation') === 'true' ? '/chatsAdmin' : '/chats'}>
              <img src={bell} alt="bell" />
              {badgeVisible && unreadCount > 0 && (
                <span className="notification-badge">{unreadCount}</span>
              )}
            </Link>
          </div>
          {/* Inserisci il menu dell'account */}
          <AccountMenu accountImage={user_fake} />
        </ul>
      </Box>
    </Box>
  );
}

export default Header;

import React from "react";
import RadarChart from "../radarChart";

export default function AdvisoryTotalRankingWidget({ startups, datasets, labels, emptyLabels }) {
  // Usa `emptyLabels` se definito, altrimenti usa `labels`
  const computedLabels = emptyLabels || labels;

  return (
    <div className="flex flex-wrap xl:flex-nowrap gap-5 items-center bg-black rounded-2xl w-full max-w-full">
      <RadarChart datasets={datasets} labels={computedLabels} showLegend={false} />
    </div>
  );
}

import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import StandardButton from './standardButton';
import StandardTextfield from './standardTextfield';
import axios from 'axios';

export default function AddCompanyMemberDialog({ open, onClose }) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Gestisce i cambiamenti degli input
  const handleChange = (e, field) => {
    if (field === 'email') setEmail(e.target.value);
    if (field === 'message') setMessage(e.target.value);
    setError(''); // Resetta gli errori ogni volta che i dati cambiano
  };

  // Validazione dei dati
  const validateInputs = () => {
    if (!email.trim()) {
      setError('Email is required');
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Please enter a valid email address');
      return false;
    }
    if (!message.trim()) {
      setError('Message is required');
      return false;
    }
    return true;
  };

  // Gestisce il submit del form
  const handleSubmit = async () => {
    if (!validateInputs()) return;

    setIsSubmitting(true);

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Missing authentication token');
      }

      const url = `${process.env.REACT_APP_API_URL}/members/invite`;
      await axios.post(
        url,
        { email, message },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Mostra un messaggio di successo e chiudi la dialog
      alert('Invite sent successfully!');
      setEmail('');
      setMessage('');
      onClose();
    } catch (err) {
      console.error(err);
      setError('Failed to send invite. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: "16px !important",
          overflow: 'hidden',
          backgroundColor: "#191919",
        },
      }}
    >
      <DialogContent sx={{ fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919" }}>
        <div className="flex flex-col gap-10 justify-center items-center">
          <h1 className="text-6xl text-white">Add Company Member</h1>
          <div className="flex flex-col gap-4 w-full">            
            <div className="flex flex-col items-center gap-4 w-full">
              <StandardTextfield
                label="Email"
                value={email}
                onChange={(e) => handleChange(e, 'email')}
                sx={{ width: "50%" }} // Centra il campo email
              />
            </div>
            <div className="flex flex-col items-center gap-4 w-full">
              <StandardTextfield
                label="Message"
                value={message}
                onChange={(e) => handleChange(e, 'message')}
                multiline
                sx={{ width: "50%" }}
                rows={4} // Area di testo con 4 righe
              />
            </div>
            <div className="flex flex-col items-center gap-4 w-full">
                {error && (
                  <span className="text-red-500 text-sm">{error}</span>
                )}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          fontFamily: "Urbanist, sans-serif",
          backgroundColor: "#191919",
          paddingTop: "40px",
          justifyContent: 'center',
          gap: '50px',
        }}
      >
        <StandardButton
          variant="outlined"
          onClick={onClose}
          text="Cancel"
          width="300px"
          type="secondary"
          disabled={isSubmitting}
        />
        <StandardButton
          onClick={handleSubmit}
          text={isSubmitting ? "Submitting..." : "Submit"}
          width="300px"
          disabled={isSubmitting}
        />
      </DialogActions>
    </Dialog>
  );
}

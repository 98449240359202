import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './header';
import Sidebar from './sidebar';
import { Box } from '@mui/material';
import axios from 'axios';

const Layout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [bypassTokenCheck, setBypassTokenCheck] = useState(false);

  // Define paths where Header and Sidebar should be hidden
  const hideHeaderAndSidebar =
    location.pathname === '/auth' ||
    location.pathname === '/lander-survey' ||
    location.pathname === '/register-invite' ||
    location.pathname === '/register';

  useEffect(() => {
    const checkToken = async () => {
      const queryParams = new URLSearchParams(location.search);
      const queryToken = queryParams.get('token');

      if (queryToken) {
        // Save the token from the query string to localStorage
        localStorage.setItem('token', queryToken);
        console.log('Token saved from query string');
        // Bypass further token validation
        setBypassTokenCheck(true);
        setIsLoading(false);
        return;
      }

      if (!hideHeaderAndSidebar) {
        const token = localStorage.getItem('token');
        if (token) {
          try {
            // Check if the token is valid
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (response.status === 200) {
              //console.log('Token is valid');
            }
          } catch (error) {
            console.error('Invalid or expired token', error);
            localStorage.removeItem('token');
            navigate('/auth');
          }
        } else {
          navigate('/auth');
        }
      }
      setIsLoading(false); // Stop loading after the check
    };

    checkToken();
  }, [navigate, location, hideHeaderAndSidebar]);

  if (isLoading) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Render Header only if not on paths where it should be hidden and not bypassing token check */}
      {!hideHeaderAndSidebar && !bypassTokenCheck && <Header />}

      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {/* Render Sidebar only if not on paths where it should be hidden and not bypassing token check */}
        {!hideHeaderAndSidebar && !bypassTokenCheck && <Sidebar />}

        {/* Main content */}
        <Box component="main" sx={{ flexGrow: 1, overflow: 'hidden' }}>
          {children}
        </Box>
      </Box>
    </div>
  );
};

export default Layout;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, ListItemButton, IconButton, Box, Typography, Button, Collapse } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import axios from 'axios';

// Import icons
import dashboard from '../assets/icons/dashboard.png';
import scout_startups from '../assets/icons/scout_startups.png';
import saved from '../assets/icons/saved.png';
import chats from '../assets/icons/chats.png';
import ai_navigator from '../assets/icons/ai_navigator.png';
import advisory_program from '../assets/icons/advisory_program.png';
import survey_img from '../assets/icons/survey_img.png'
import account from '../assets/icons/account.png';
import StandardButton from './standardButton';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const [isAdminUser, setAdminUser] = useState(() => {
    const storedIsAdminUser = localStorage.getItem('isAdminUser');
    if (storedIsAdminUser !== null) {
      return JSON.parse(storedIsAdminUser);
    } else {
      return localStorage.getItem('roleName') === 'admin';
    }
  });
  
  const [mobileOpen, setMobileOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false); // State for Account submenu
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate()


  const toggleDrawer = (isOpen) => () => {
    setMobileOpen(isOpen);
  };

  // Funzione per recuperare le categorie dall'API
  const logout = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${process.env.REACT_APP_API_URL}/logout`, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('companyId');
      localStorage.removeItem('admin');
      localStorage.removeItem('isAdminUser');
      localStorage.removeItem('roleName');
      localStorage.removeItem('innovation');
      localStorage.removeItem('adminPermissions');
      localStorage.removeItem('account_completed');
      navigate('/auth');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
  

  const toggleAccountMenu = () => {
    setAccountOpen(!accountOpen);
  };

  const toggleUser = () => {
    setAdminUser((prevIsAdminUser) => {
      const newIsAdminUser = !prevIsAdminUser;
      localStorage.setItem('isAdminUser', JSON.stringify(newIsAdminUser));
      return newIsAdminUser;
    });
  };
  

  // Stato per le categorie
  const [sectors, setSectors] = useState([]);

  // Funzione per recuperare le categorie dall'API
  const fetchSectors = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/taxonomies?type=category`);
      // Filtra le categorie di primo livello (level === 1)
      //const topLevelSectors = response.data.data.filter((sector) => sector.level === 1);
      setSectors(response.data.data);
    } catch (error) {
      console.error('Errore nel recupero delle categorie:', error);
    }
  };

  useEffect(() => {
    fetchSectors();
  }, []);

  // Mappatura tra nomi delle categorie e icone esistenti
  const sectorIcons = {
    Grocery: dashboard, // Sostituisci con l'icona corretta
    Retail: dashboard,
    Automotive: dashboard,
    Logistics: dashboard,
    Finance: dashboard,
    // Aggiungi altre mappature se necessario
  };

  if (location.pathname === '/lander-survey' || location.pathname === '/register') {
    return null;
  }
  

  const drawer = (
    <Box sx={{ height: '100%', backgroundColor: '#191919' }}>
      {!isAdminUser && (
        <Box>
          <Typography variant="h6" sx={{ paddingTop: '25px', paddingBottom: '5px', paddingLeft: '25px', color: 'white', fontFamily: 'Urbanist, sans-serif' }}>
            Home
          </Typography>

          <List className={'SidebarMenuList'} sx={{ paddingLeft: '25px' }}>
            <ListItemButton component={Link} to="/">
              <Box component="img" src={dashboard} alt="Home" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Home" />
            </ListItemButton>
            <ListItemButton component={Link} to="/startups">
              <Box component="img" src={scout_startups} alt="Startups" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Startups" />
            </ListItemButton>
            <ListItemButton component={Link} to="/bookmarks-startups">
              <Box component="img" src={saved} alt="Saved" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Saved" />
            </ListItemButton>
            
            {/* Controllo Chat o ChatAdmin */}
            
            <ListItemButton              
              component={Link}
              to={localStorage.getItem("innovation") === "true" ? "/chatsAdmin" : "/chats"}
            >
              <Box
                component="img"
                src={chats}
                alt="Chats"
                sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }}
              />
              <ListItemText primary="Chats" />
            </ListItemButton>

            <ListItemButton component={Link} to="/survey/chat">
              <Box component="img" src={ai_navigator} alt="AI Navigator" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="AI Navigator" />
            </ListItemButton>
            <ListItemButton component={Link} to="/advisory">
              <Box component="img" src={advisory_program} alt="Advisory Program" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Advisory Program" />
            </ListItemButton>
            <ListItemButton component={Link} to="/survey">
              <Box component="img" src={survey_img} alt="Survey" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Survey" />
            </ListItemButton>

            
          </List>

          <Typography variant="h6" sx={{ paddingTop: '25px', paddingBottom: '5px', paddingLeft: '25px', color: 'white', fontFamily: 'Urbanist, sans-serif' }}>
            Sectors
          </Typography>

          <Box
            sx={{
              maxHeight: '200px',
              overflowY: 'auto',
              paddingLeft: '25px',
              pr: 2,
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#C5FF55',
                borderRadius: '10px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#2B2C2D',
              },
            }}
          >
            <List className={'SidebarMenuList'}>
              {sectors.map((sector) => (
                <ListItemButton component={Link} to={`/startups?categories%5B%5D=${sector.id}&name%5B%5D=${sector.name}`} key={sector.id}>
                  {/* Usa l'icona associata al nome della categoria, se disponibile */}
                  <Box
                    component="img"
                    src={sector.logo || dashboard}
                    alt={sector.name}
                    sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }}
                  />
                  <ListItemText primary={sector.name} />
                </ListItemButton>
              ))}
            </List>

          </Box>

          <Box sx={{ padding: '20px 25px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Button component={Link} to={"/startups"} variant="contained" fullWidth sx={{ fontSize: '16px', fontFamily: 'Urbanist, sans-serif', backgroundColor: '#C5FF55', color: '#191919', fontWeight: 700, textTransform: 'capitalize' }}>
              Advanced Search &rarr;
            </Button>
            <StandardButton text="Logout" onClick={logout} />
            {
              localStorage.getItem('roleName') === 'admin' &&
              <StandardButton text={"Admin Area"} onClick={toggleUser} />
            }
          </Box>
        </Box>
      )}
      {isAdminUser && (
        <Box sx={{ height: '100%' }}>
          <List className={'SidebarMenuList'} sx={{ paddingLeft: '25px', height: '100%' }}>

            <Typography sx={{ paddingTop: '12px', paddingBottom: '5px', paddingLeft: '12px', color: 'white', fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold', fontSize: '1.1rem' }}>
              Dashboard
            </Typography>
            <ListItemButton component={Link} to="/dashboard">
              <Box component="img" src={dashboard} alt="Dashboard" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Dashboard" />
            </ListItemButton>

            <Typography sx={{ paddingTop: '12px', paddingBottom: '5px', paddingLeft: '12px', color: 'white', fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold', fontSize: '1.1rem' }}>
              Users
            </Typography>
            <ListItemButton component={Link} to="/userList">
              <Box component="img" src={account} alt="User List" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="User List" />
            </ListItemButton>

            <Typography sx={{ paddingTop: '12px', paddingBottom: '5px', paddingLeft: '12px', color: 'white', fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold', fontSize: '1.1rem' }}>
              Startups
            </Typography>
            <ListItemButton component={Link} to="/startupList">
              <Box component="img" src={scout_startups} alt="Startup list" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Startup list" />
            </ListItemButton>

            <Typography sx={{ paddingTop: '12px', paddingBottom: '5px', paddingLeft: '12px', color: 'white', fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold', fontSize: '1.1rem' }}>
              Analysis
            </Typography>
            <ListItemButton component={Link} to="/analysis">
              <Box component="img" src={survey_img} alt="Analysis" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Analysis" />
            </ListItemButton>



            {/* Other items for admin user */}
            <Box sx={{ padding: '20px 25px', display: 'flex', flexDirection: 'column', gap: '20px' }}>              
              <StandardButton text="Logout" onClick={logout} />
              {
                localStorage.getItem('roleName') === 'admin' &&
                <StandardButton text={"Standard Area"} onClick={toggleUser} />
              }
            </Box>
          </List>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : true}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            position: 'relative',
            backgroundColor: '#231F20',
            width: 250,
          },
        }}
      >
        {drawer}
      </Drawer>

      {isMobile && (
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer(true)}
          sx={{
            position: 'absolute',
            top: 25,
            right: 10,
            color: '#C5FF55',
            '&:hover': {
              backgroundColor: 'rgba(197, 255, 85, 0.1)',
            },
          }}
        >
          <MenuIcon sx={{ color: '#C5FF55' }} />
        </IconButton>
      )}
    </>
  );
}

export default Sidebar;

import React, { useEffect, useState } from "react";
import PaginatedTable from "../paginatedTable";
import axios from "axios";

export default function UserActivity({ userId }) {
  const [items, setItems] = useState([]); // Righe finali per la tabella
  const [loading, setLoading] = useState(false);

  // Intestazioni fisse con larghezza uniforme
  const header = [
    { field: "searchedOn", headerName: "Searched On", flex: 0.5 },
    { field: "keywords", headerName: "Type", flex: 1 },
    { field: "tags", headerName: "Value", flex: 1 },
  ];

  useEffect(() => {
    if (!userId) return;

    const fetchActivities = async () => {
      const token = localStorage.getItem("token"); // Se necessario
      const url = `${process.env.REACT_APP_API_URL}/activities?user_id=${userId}`;
      const response = await axios.get(url, {
        headers: {
          // Se serve, aggiungi Bearer token: Authorization: `Bearer ${token}`
        },
      });
      return response.data.data; // array di oggetti { id, user_id, type_id, value, created_at, type: { id, name } }
    };

    const loadData = async () => {
      try {
        setLoading(true);

        // Ottieni le attività
        const activities = await fetchActivities();

        // Mappa le attività in righe individuali
        const finalRows = activities.map((act) => ({
          id: act.id, // Usa l'id dell'attività come chiave unica
          searchedOn: act.created_at, // Data dell'attività
          keywords: act.type?.name || "N/A", // Nome del tipo
          tags: act.value || "N/A", // Valore associato al tipo
        }));

        setItems(finalRows);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [userId]);

  if (loading) {
    return <div>Loading user activity...</div>;
  }

  return (
    <React.Fragment>
      <h1 className="text-4xl font-medium mb-2">Search Activity</h1>
      <p>Keywords and Tags searched for</p>

      {/* Uso PaginatedTable con colonne flessibili */}
      <PaginatedTable
        header={header}
        items={items}
        perPage={5} // es. paginazione a 5 per pagina
      />
    </React.Fragment>
  );
}

import React, { useState } from "react";
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Alert,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Creazione del tema personalizzato con font Urbanist
const theme = createTheme({
    typography: {
        fontFamily: "Urbanist, Arial, sans-serif",
    },
    palette: {
        primary: {
            main: "#c5ff55", // Colore personalizzato per i bottoni
        },
    },
});

export default function Register() {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
    });
    const [error, setError] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validatePassword = () => {
        const { password, confirmPassword } = formData;
        const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;

        if (!passwordRegex.test(password)) {
            setError(
                "The password must contain at least one special character, one number and be at least 6 characters long."
            );
            return false;
        }
        if (password !== confirmPassword) {
            setError("Password doesn't match");
            return false;
        }
        setError("");
        return true;
    };

    const nextStep = () => {
        if (step === 1) {
            // Controlla se Name e Last Name sono stati compilati
            if (!formData.firstName.trim() || !formData.lastName.trim()) {
                setError("Please fill in both Name and Last Name.");
                return;
            }
            setError(""); // Rimuovi eventuali errori precedenti
        }
    
        if (step === 2 && !validatePassword()) return;
    
        setStep((prev) => prev + 1);
    };
    

    const prevStep = () => setStep((prev) => prev - 1);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validatePassword()) {
            console.log("Form data submitted:", formData);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container
                maxWidth="sm"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100vh",
                    alignItems: "center",                    
                }}
            >
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        width: "100%",
                        padding: 3,
                        backgroundColor: "#191919", // Sfondo personalizzato
                        borderRadius: 2,
                        boxShadow: 3,
                        color: "#fff",
                        backgroundColor: "#191919",
                    }}
                >
                    {step === 1 && (
                        <Box>
                            <Typography variant="h5" gutterBottom>
                                Personal Details
                            </Typography>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                                sx={{
                                    input: { color: "#fff" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Last name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                                sx={{
                                    input: { color: "#fff" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                    },
                                }}
                            />
                            {error && (
                                <Alert severity="error" sx={{ marginTop: 2 }}>
                                    {error}
                                </Alert>
                            )}
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ marginTop: 2, backgroundColor: "#c5ff55", color: "#191919" }}
                                onClick={nextStep}
                            >
                                Next
                            </Button>
                        </Box>
                    )}
                    {step === 2 && (
                        <Box>
                            <Typography variant="h5" gutterBottom>
                                Create a password
                            </Typography>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Password"
                                name="password"
                                type="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                                sx={{
                                    input: { color: "#fff" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Confirm Password"
                                name="confirmPassword"
                                type="password"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                                sx={{
                                    input: { color: "#fff" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                    },
                                }}
                            />
                            {error && (
                                <Alert severity="error" sx={{ marginTop: 2 }}>
                                    {error}
                                </Alert>
                            )}
                            <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
                                <Button
                                    variant="outlined"
                                    sx={{ borderColor: "#c5ff55", color: "#c5ff55" }}
                                    onClick={prevStep}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: "#c5ff55", color: "#191919" }}
                                    onClick={nextStep}
                                >
                                    Next
                                </Button>
                            </Box>
                        </Box>
                    )}
                    {step === 3 && (
                        <Box>
                            <Typography variant="h5" gutterBottom>
                                Summary
                            </Typography>
                            <Typography>
                                <strong>Name:</strong> {formData.firstName}
                            </Typography>
                            <Typography>
                                <strong>Surname:</strong> {formData.lastName}
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
                                <Button
                                    variant="outlined"
                                    sx={{ borderColor: "#c5ff55", color: "#c5ff55" }}
                                    onClick={prevStep}
                                >
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ backgroundColor: "#c5ff55", color: "#191919" }}
                                >
                                    Confirm
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Container>
        </ThemeProvider>
    );
}

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import axios from "axios";

const EditMemberDialog = ({ open, handleClose, userId }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    department: "",
    position: "",
    phoneNumber: "",
  });

  const [positions, setPositions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  // Carica i dati di posizione e dipartimenti
  useEffect(() => {
    const fetchDropdownData = async () => {
      const token = localStorage.getItem("token");
      try {
        const [posResp, depResp] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/values/positions`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/values/departments`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        setPositions(posResp.data.data || []);
        setDepartments(depResp.data.data || []);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
  }, []);

  // Precompila i dati del form quando viene aperta la dialog
  useEffect(() => {
    if (!userId) return;

    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/users`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const userData = response.data;

        setFormData({
          firstName: userData.first_name || "",
          lastName: userData.last_name || "",
          phoneNumber: userData.phone_number || "",
          department: userData.department_id || "",
          position: userData.position_id || "",
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  // Gestisce i cambiamenti nei campi del form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Salva i cambiamenti con una chiamata PATCH
  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      const payload = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone_number: formData.phoneNumber || "",
        department_id: formData.department ? parseInt(formData.department, 10) : null,
        position_id: formData.position ? parseInt(formData.position, 10) : null,
        tags: [],
      };

      await axios.patch(
        `${process.env.REACT_APP_API_URL}/user`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      alert("User updated successfully!");
      handleClose();
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Error updating user!");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          fontWeight: "bold",
          backgroundColor: "#191919",
          color: "white",
          textAlign: "center",
          fontFamily: "Urbanist",
        }}
      >
        Edit Member
      </DialogTitle>

      <DialogContent
        sx={{
          backgroundColor: "#191919",
          color: "white",
          padding: "24px",
          fontFamily: "Urbanist",
        }}
      >
        {loading ? (
          <p>Loading user data...</p>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstName"
                label="First Name"
                fullWidth
                variant="outlined"
                value={formData.firstName}
                onChange={handleInputChange}
                InputProps={{ style: { color: "white", fontFamily: "Urbanist" } }}
                InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="lastName"
                label="Last Name"
                fullWidth
                variant="outlined"
                value={formData.lastName}
                onChange={handleInputChange}
                InputProps={{ style: { color: "white", fontFamily: "Urbanist" } }}
                InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    color: "rgba(255, 255, 255, 0.7)",
                    fontFamily: "Urbanist",
                  }}
                >
                  Position
                </InputLabel>
                <Select
                  name="position"
                  value={formData.position}
                  onChange={handleInputChange}
                  sx={{ color: "white", fontFamily: "Urbanist" }}
                >
                  {positions.map((pos) => (
                    <MenuItem key={pos.id} value={pos.id}>
                      {pos.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    color: "rgba(255, 255, 255, 0.7)",
                    fontFamily: "Urbanist",
                  }}
                >
                  Department
                </InputLabel>
                <Select
                  name="department"
                  value={formData.department}
                  onChange={handleInputChange}
                  sx={{ color: "white", fontFamily: "Urbanist" }}
                >
                  {departments.map((dep) => (
                    <MenuItem key={dep.id} value={dep.id}>
                      {dep.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="phoneNumber"
                label="Phone Number"
                fullWidth
                variant="outlined"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                InputProps={{ style: { color: "white", fontFamily: "Urbanist" } }}
                InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          backgroundColor: "#191919",
          padding: "16px",
          justifyContent: "center",
          fontFamily: "Urbanist",
        }}
      >
        <Button
          onClick={handleSave}
          sx={{
            backgroundColor: "#A3E635",
            color: "#000",
            fontWeight: "bold",
            fontFamily: "Urbanist",
            "&:hover": { backgroundColor: "rgba(163, 230, 53, 0.8)" },
            padding: "8px 32px",
          }}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMemberDialog;

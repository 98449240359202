import React, { useState, useEffect } from "react";
import { Checkbox } from "@mui/material";
import StandardButton from "../standardButton";
import StandardTextfield from "../standardTextfield";
import StandardSelect from "../standardSelect";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; 
import EditIcon from '@mui/icons-material/Edit'; 

export default function BillingDetails() {
  const [user, setUser] = useState({
    id: null,
    firstname: "",
    lastname: "",
    role: "",
    roleOptions: [],
    department: "",
    departmentOptions: [],
    image: "",
    phone: "",
    email: "",
    faEnabled: false,
  });
  const [loading, setLoading] = useState(true);
  const [positions, setPositions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(0);
  const [selectedPositionId, setSelectedPositionId] = useState(0);
  const [selectedRoleId, setSelectedRoleId] = useState(0);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const isUserAdmin = localStorage.getItem("roleName") === "admin";

  const handleChange = (event, field) => {
    switch (field) {
      case "firstname":
        setFirstname(event.target.value);
        break;
      case "lastname":
        setLastname(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;
      case "department":
        setSelectedDepartmentId(event.target.value);
        break;
      case "position":
        setSelectedPositionId(event.target.value);
        break;
      case "role":
        setSelectedRoleId(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleResetUser = () => {
    setFirstname(user.firstname);
    setLastname(user.lastname);
    setPhone(user.phone);
    setSelectedDepartmentId(user.department);
    setSelectedPositionId(user.position);
    setSelectedRoleId(user.role);
  };

  const handleEditUser = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/user`,
        {
          first_name: firstname,
          last_name: lastname,
          phone_number: phone,
          department_id: selectedDepartmentId,
          position_id: selectedPositionId,
          tags: [],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  return (
    <React.Fragment>      
        <div className="flex flex-col gap-8 w-full">
            <h1 className="text-4xl font-medium mb-0">Billing Address</h1>
            <div className="grid grid-cols-2 gap-x-12 gap-y-6">
              <StandardTextfield
                label="Legal Name"
                value={firstname}
                onChange={(e) => {
                  handleChange(e, "firstname");
                }}
              />
              <StandardSelect
                label="Country"
                value={selectedPositionId}
                items={positions}
                optionValue="id"
                optionLabel="name"
                onChange={(e) => {
                  handleChange(e, "position");
                }}
              />
              <StandardTextfield
                label="City"
                value={lastname}
                onChange={(e) => {
                  handleChange(e, "lastname");
                }}
              />              
              <StandardTextfield
                label="Address"
                value={lastname}
                onChange={(e) => {
                  handleChange(e, "lastname");
                }}
              />  
              <StandardTextfield
                label="VAT Number"
                value={lastname}
                onChange={(e) => {
                  handleChange(e, "lastname");
                }}
              />
              <StandardTextfield
                label="Post ZIP Code"
                value={lastname}
                onChange={(e) => {
                  handleChange(e, "lastname");
                }}
              />
            </div>

            <div className="grid grid-cols-2 gap-x-12 gap-y-6 mt-4">
                <div className="flex flex-row items-center gap-2 ">
                    <h2 className="text-4xl font-semibold m-0">Company Information</h2>
                    <EditIcon
                        sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                        onClick={console.log('click')}
                    />
                </div>

                <div className="flex flex-row items-center gap-2 ">
                    <h2 className="text-4xl font-semibold m-0">Access Control</h2>
                </div>

                <div className="flex flex-col gap-2 ">
                    <p><strong>Current Plan:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"></span></p>
                    <p><strong>Next Billing Date:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"></span></p>
                    <p><strong>Plan Interval:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"></span></p>
                    <p><strong>Amount</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"></span></p>
                </div>

                <div className="flex flex-col gap-5 ">
                    <div className="flex flex-row items-center"> 
                        <p style={{width: "50%", margin: "0"}}><strong>Plan Expiry Date:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"></span></p>
                        <StandardButton
                          variant="outlined"
                          onClick={console.log('click')}
                          text="Update Plan Expiry Date"
                          width="50%"
                        />
                    </div>

                    <div className="flex flex-row items-center"> 
                        <p style={{width: "50%", margin: "0"}}><strong>Trial Period End:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"></span></p>
                        <StandardButton
                          variant="outlined"
                          onClick={console.log('click')}
                          text="Update Plan Expiry Date"
                          width="50%"
                        />
                    </div>
                </div>

            </div>

            <div className="flex flex-col gap-2 ">
                <h2 className="text-4xl font-semibold m-0">Payment Request</h2>
                <p>Table will be here</p>
            </div>
        </div>
    </React.Fragment>
  );
}

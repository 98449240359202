import React from "react";
import PaginatedTable from '../components/paginatedTable'

import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import NotificationsActive from "@mui/icons-material/NotificationsActive";

const subscriptionItems = [
  { id: 1, planType: 'Single User', startupTags: 'Plan applied to Single User'},
  { id: 2, planType: 'Multi User', startupTags: 'Plan applied to the Companies'},
  { id: 3, planType: 'Enterprise', startupTags: 'Plan applied to Users with the promo code'}
];

const items = [
  { id: 1, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
  { id: 2, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
  { id: 3, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
  { id: 4, userRole: 'Company', singleUser: true, multiUser: true, enterprise: false,},
  { id: 5, userRole: 'Company', singleUser: true, multiUser: true, enterprise: false,},
  { id: 6, userRole: 'Company', singleUser: true, multiUser: true, enterprise: false,},
  { id: 7, userRole: 'Company', singleUser: true, multiUser: true, enterprise: false,},
  { id: 8, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
  { id: 9, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
  { id: 10, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
  { id: 11, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
  { id: 12, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
  { id: 13, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
  { id: 14, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
  { id: 15, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
  { id: 16, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
  { id: 17, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
  { id: 18, userRole: 'Company', singleUser: true, multiUser: true, enterprise: true,},
];

function IconColumn(params) {
  if(params.value)
    return (
      <div className="flex h-full items-center">
        <div className="bg-green-500 w-10 h-10 flex items-center justify-center rounded-full">
          <DoneIcon />
        </div>
      </div>
    )
  else
    return (
    <div className="flex h-full items-center">
      <div className="bg-red-500 w-10 h-10 flex items-center justify-center rounded-full">
        <CancelIcon />
      </div>
    </div>
    )
}
export default function PlanTypes() {
  const [rows, setRows] = React.useState(items);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const header = [
    { field: 'userRole', headerName: 'User Role', width: 200 },
    { field: 'singleUser', headerName: 'Single User', width: 130, type: 'custom', renderCell: IconColumn,  },
    { field: 'multiUser', headerName: 'Multi User', width: 130, type: 'custom', renderCell: IconColumn,  },
    { field: 'enterprise', headerName: 'Enterprise', width: 130, type: 'custom', renderCell: IconColumn,  },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
  
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    }
  ];
  
  const subscriptionHeader = [
    { field: 'id', headerName: 'Id', type: 'number', width: 120 },
    { field: 'planType', headerName: 'Plan type', width: 200 },
    { field: 'startupTags', headerName: 'Tags per Startup', width: 500 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
  
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    }
  ];

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (!!editedRow && editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-[calc100vh]">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full">
          
          <div className="flex items-center w-full">
            <h1 className="text-6xl font-medium">Subscription Plan Types</h1>
          </div>
          <div>
            <PaginatedTable hideFooter={true} header={subscriptionHeader} items={subscriptionItems} />
          </div>
          <h1 className="text-6xl font-medium mt-5">User Types and Plans Associations</h1>
          <div className="flex gap-4 items-center">
            <NotificationsActive sx={{color: "rgb(var(--global-color-primary))"}}/>
            <span>SuperAdmin should be able to analyse and produce a report of the registered companies through its given keywords.</span>
          </div>
          <PaginatedTable header={header} items={items} height={"600px"} />
        </div>
      </div>
    </div>
  )
}
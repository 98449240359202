import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";

const DialogTags2 = ({ open, handleClose, onSave, selectedTags: initialSelectedTags }) => {
  const [categories, setCategories] = useState([]); // Tutti i tag disponibili
  const [selectedTags, setSelectedTags] = useState(initialSelectedTags || []); // Tag selezionati
  const [groupedCategories, setGroupedCategories] = useState({}); // Tag raggruppati per lettera

  useEffect(() => {
    // Aggiorna i tag preselezionati quando cambiano in `props`
    if (initialSelectedTags) {
      setSelectedTags(initialSelectedTags);
    }
  }, [initialSelectedTags]);

  useEffect(() => {
    // Chiamata API per ottenere tutti i tag disponibili
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/taxonomies?type=tag`);
        const tags = response.data.data || [];
        setCategories(tags);
        setGroupedCategories(groupCategoriesAlphabetically(tags));
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Funzione per raggruppare i tag alfabeticamente
  const groupCategoriesAlphabetically = (tags) => {
    return tags.reduce((acc, tag) => {
      const letter = tag.name[0].toUpperCase();
      if (!acc[letter]) {
        acc[letter] = [];
      }
      acc[letter].push(tag);
      return acc;
    }, {});
  };

  // Aggiunge un tag selezionato
  const handleTagSelect = (tag) => {
    if (!selectedTags.some((t) => t.id === tag.id)) {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  // Rimuove un tag selezionato
  const handleTagRemove = (tagId) => {
    setSelectedTags(selectedTags.filter((tag) => tag.id !== tagId));
  };

  // Salvataggio
  const handleSave = () => {
    onSave(selectedTags); // Passa i tag modificati al padre
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle
        sx={{
          fontWeight: "bold",
          backgroundColor: "#191919",
          color: "white",
          fontFamily: "Urbanist",
        }}
      >
        Associated Tags
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#191919", color: "white" }}>
        {/* Tag già associati */}
        <div className="flex flex-wrap gap-2 mb-4">
          {selectedTags.map((tag) => (
            <Chip
              key={tag.id}
              label={tag.name}
              onDelete={() => handleTagRemove(tag.id)}
              deleteIcon={
                <span
                  style={{
                    color: "rgb(var(--global-color-primary))",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  &times;
                </span>
              }
              sx={{
                backgroundColor: "transparent",
                border: "1.5px solid rgb(var(--global-color-primary))",
                borderRadius: "5px",
                fontFamily: "Urbanist",
                color: "rgb(var(--global-color-primary))",
                fontWeight: "bold",
              }}
            />
          ))}
        </div>

        {/* Accordion per i tag disponibili */}
        {Object.keys(groupedCategories)
          .sort()
          .map((letter) => (
            <Accordion key={letter}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                sx={{
                  backgroundColor: "#5a5b5b",
                  color: "#fff",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontFamily: "Urbanist" }}>
                  {letter}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#191919",
                  boxShadow: "none",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                }}
              >
                {groupedCategories[letter].map((category) => (
                  <Chip
                    key={category.id}
                    label={category.name}
                    onClick={() => handleTagSelect(category)}
                    disabled={selectedTags.some((t) => t.id === category.id)}
                    sx={{
                      backgroundColor: selectedTags.some((t) => t.id === category.id)
                        ? "rgba(255,255,255,0.3)"
                        : "white",
                      fontFamily: "Urbanist",
                      color: selectedTags.some((t) => t.id === category.id)
                        ? "gray"
                        : "black",
                      fontWeight: "bold",
                      cursor: selectedTags.some((t) => t.id === category.id)
                        ? "not-allowed"
                        : "pointer",
                      "&:hover": {
                        backgroundColor: selectedTags.some((t) => t.id === category.id)
                          ? "rgba(255,255,255,0.3)"
                          : "rgb(var(--global-color-primary))",
                        color: "black",
                      },
                    }}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#191919" }}>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "gray",
            color: "white",
            "&:hover": { backgroundColor: "rgba(128, 128, 128, 0.8)" },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          sx={{
            backgroundColor: "rgb(var(--global-color-primary))",
            color: "black",
            fontWeight: "bold",
            "&:hover": { backgroundColor: "rgba(var(--global-color-primary), 0.8)" },
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogTags2;

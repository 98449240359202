import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Chip, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";

const DialogTags2 = ({ open, handleClose, startupData, innovationId }) => {
  const [categories, setCategories] = useState([]); // Tutti i tag disponibili
  const [selectedTags, setSelectedTags] = useState([]); // Tag selezionati dall'innovation
  const [groupedCategories, setGroupedCategories] = useState({}); // Tag raggruppati per lettera

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        if (!apiUrl) {
          console.error("API URL is not defined.");
          return;
        }

        // Effettua chiamate parallele per ottimizzare
        const [taxonomyResponse, innovationResponse] = await Promise.all([
          axios.get(`${apiUrl}/taxonomies?type=tag`),
          axios.get(`${apiUrl}/innovations/${innovationId}`)
        ]);

        // Dati dai tag disponibili
        const taxonomyData = taxonomyResponse.data.data;

        // Dati dai tag associati all'innovation
        const innovationTags = innovationResponse.data.data.taxonomies?.tags || [];

        setCategories(taxonomyData); // Salva tutti i tag disponibili
        setSelectedTags(innovationTags); // Salva i tag già associati

        // Raggruppa i tag per lettera iniziale
        const grouped = groupCategoriesAlphabetically(taxonomyData);
        setGroupedCategories(grouped);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [innovationId]);

  // Funzione per raggruppare i tag alfabeticamente
  const groupCategoriesAlphabetically = (tags) => {
    return tags.reduce((acc, tag) => {
      const letter = tag.name[0].toUpperCase(); // Prendi la lettera iniziale
      if (!acc[letter]) {
        acc[letter] = [];
      }
      acc[letter].push(tag);
      return acc;
    }, {});
  };

  // Aggiungi un tag selezionato
  const handleTagSelect = (tag) => {
    if (!selectedTags.some((t) => t.id === tag.id)) {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  // Rimuovi un tag selezionato
  const handleTagRemove = (tagId) => {
    setSelectedTags(selectedTags.filter((tag) => tag.id !== tagId));
  };

  // Salvataggio dei tag selezionati
  const handleSave = async () => {
    try {
      const updatedTags = selectedTags.map((tag) => tag.id);
      const payload = { tags: updatedTags }; // Cambia in base alla struttura richiesta

      const apiUrl = process.env.REACT_APP_API_URL;
      await axios.patch(`${apiUrl}/innovations/${innovationId}`, payload);

      console.log("Tags updated successfully:", updatedTags);
      handleClose(); // Chiudi il dialog
    } catch (error) {
      console.error("Error saving tags:", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle
        sx={{
          fontWeight: "bold",
          backgroundColor: "#191919",
          color: "white",
          fontFamily: "Urbanist",
        }}
      >
        Associated Tags
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#191919", color: "white" }}>
        {/* Tag già associati */}
        <div className="flex flex-wrap gap-2 mb-4">
          {selectedTags.map((tag) => (
            <Chip
              key={tag.id}
              label={tag.name}
              onDelete={() => handleTagRemove(tag.id)}
              deleteIcon={
                <span
                  style={{
                    color: "rgb(var(--global-color-primary))",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  &times;
                </span>
              }
              sx={{
                backgroundColor: "transparent",
                border: "1.5px solid rgb(var(--global-color-primary))",
                borderRadius: "5px",
                fontFamily: "Urbanist",
                color: "rgb(var(--global-color-primary))",
                fontWeight: "bold",
              }}
            />
          ))}
        </div>

        {/* Accordion per i tag disponibili */}
        {Object.keys(groupedCategories)
          .sort() // Ordina alfabeticamente
          .map((letter) => (
            <Accordion key={letter}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{color: "#fff"}}/>}
                sx={{
                  backgroundColor: "#5a5b5b",
                  color: "#fff",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontFamily: "Urbanist" }}>
                  {letter}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#191919",
                  boxShadow: "none",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                }}
              >
                {groupedCategories[letter].map((category) => (
                  <Chip
                    key={category.id}
                    label={category.name}
                    onClick={() => handleTagSelect(category)}
                    disabled={selectedTags.some((t) => t.id === category.id)} // Disabilita se già selezionato
                    sx={{
                      backgroundColor: selectedTags.some((t) => t.id === category.id)
                        ? "rgba(255,255,255,0.3)"
                        : "white",
                      fontFamily: "Urbanist",
                      color: selectedTags.some((t) => t.id === category.id)
                        ? "gray"
                        : "black",
                      fontWeight: "bold",
                      cursor: selectedTags.some((t) => t.id === category.id)
                        ? "not-allowed"
                        : "pointer",
                      "&:hover": {
                        backgroundColor: selectedTags.some((t) => t.id === category.id)
                          ? "rgba(255,255,255,0.3)"
                          : "rgb(var(--global-color-primary))",
                        color: "black",
                      },
                    }}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#191919" }}>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "gray",
            color: "white",
            "&:hover": { backgroundColor: "rgba(128, 128, 128, 0.8)" },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          sx={{
            backgroundColor: "rgb(var(--global-color-primary))",
            color: "black",
            fontWeight: "bold",
            "&:hover": { backgroundColor: "rgba(var(--global-color-primary), 0.8)" },
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogTags2;

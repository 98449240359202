import React, { useState, useEffect } from "react";
import { Checkbox } from "@mui/material";
import StandardButton from "../standardButton";
import StandardTextfield from "../standardTextfield";
import StandardSelect from "../standardSelect";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; 

export default function AccountInformation({userId, companyId}) {
  const [user, setUser] = useState({
    id: null,
    firstname: "",
    lastname: "",
    role: "",
    roleOptions: [],
    department: "",
    departmentOptions: [],
    image: "",
    phone: "",
    email: "",
    faEnabled: false,
  });
  const [loading, setLoading] = useState(true);
  const [positions, setPositions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(0);
  const [selectedPositionId, setSelectedPositionId] = useState(0);
  const [selectedRoleId, setSelectedRoleId] = useState(0);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const isUserAdmin = localStorage.getItem("roleName") === "admin";

  const handleChange = (event, field) => {
    switch (field) {
      case "firstname":
        setFirstname(event.target.value);
        break;
      case "lastname":
        setLastname(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;
      case "department":
        setSelectedDepartmentId(event.target.value);
        break;
      case "position":
        setSelectedPositionId(event.target.value);
        break;
      case "role":
        setSelectedRoleId(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleResetUser = () => {
    setFirstname(user.firstname);
    setLastname(user.lastname);
    setPhone(user.phone);
    setSelectedDepartmentId(user.department);
    setSelectedPositionId(user.position);
    setSelectedRoleId(user.role);
  };

  const handleEditUser = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/user`,
        {
          first_name: firstname,
          last_name: lastname,
          phone_number: phone,
          department_id: selectedDepartmentId,
          position_id: selectedPositionId,
          tags: [],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchDatas = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const urlUser = `${process.env.REACT_APP_API_URL}/user`;
      const urlDepartments = `${process.env.REACT_APP_API_URL}/values/departments`;
      const urlPositions = `${process.env.REACT_APP_API_URL}/values/positions`;
      const urlRoles = `${process.env.REACT_APP_API_URL}/values/roles`;

      const response = await axios.get(urlUser, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const departmentResponse = await axios.get(urlDepartments, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const positionsResponse = await axios.get(urlPositions, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const rolesResponse = await axios.get(urlRoles, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setDepartments(departmentResponse.data.data);
      setPositions(positionsResponse.data.data);
      setRoles(rolesResponse.data.data);

      setUser((prevUser) => ({
        ...prevUser,
        id: response.data.id || null,
        firstname: response.data.first_name || "",
        lastname: response.data.last_name || "",
        phone: response.data.phone_number || "",
        email: response.data.email || "",
        role: response.data.companies[0]?.member_details?.role?.id || undefined,
        position: response.data.companies[0]?.member_details?.position?.id || undefined,
        department: response.data.companies[0]?.member_details?.department?.id || "",
        faEnabled: prevUser.faEnabled,
      }));

      setFirstname(response.data.first_name);
      setLastname(response.data.last_name);
      setPhone(response.data.phone_number);
      setSelectedDepartmentId(
        response.data.companies[0]?.member_details?.department?.id || undefined
      );
      setSelectedPositionId(
        response.data.companies[0]?.member_details?.position?.id || undefined
      );
      setSelectedRoleId(
        response.data.companies[0]?.member_details?.role?.id || undefined
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  return (
    <React.Fragment>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <div className="flex flex-col gap-8 w-full">
            <h1 className="text-4xl font-medium mb-8">Account Information</h1>
            <div className="grid grid-cols-2 gap-x-12 gap-y-6">
              <StandardTextfield
                label="First Name"
                value={firstname}
                onChange={(e) => {
                  handleChange(e, "firstname");
                }}
              />
              <StandardTextfield
                label="Last Name"
                value={lastname}
                onChange={(e) => {
                  handleChange(e, "lastname");
                }}
              />
              <StandardSelect
                label="Position"
                value={selectedPositionId}
                items={positions}
                optionValue="id"
                optionLabel="name"
                onChange={(e) => {
                  handleChange(e, "position");
                }}
              />
              <StandardSelect
                label="Department"
                value={selectedDepartmentId}
                items={departments}
                optionValue="id"
                optionLabel="name"
                onChange={(e) => {
                  handleChange(e, "department");
                }}
              />
              {/* Telefono */}
<div className="relative w-full">
  <PhoneInput
    country={"us"} // Puoi cambiare il paese di default
    value={phone}
    onChange={handlePhoneChange}
    placeholder="Phone"
    inputStyle={{
      backgroundColor: "transparent",
      border: "1px solid #fff",
      borderRadius: "5px",
      color: "white",
      width: "100%",
      fontFamily: "Urbanist, sans-serif",
      fontWeight: "500",
      fontSize: "16px",
      paddingLeft: "50px", // Per lasciare spazio alla bandierina
      height: "56px",
    }}
    buttonStyle={{
      backgroundColor: "transparent",
      border: "none",
      width: "50px", // Uniforme con il padding del testo
      borderRadius: "5px 0 0 5px",
    }}
    dropdownStyle={{
      backgroundColor: "#333",
      color: "white",
    }}
  />
</div>

              <StandardTextfield label="Email" value={user.email} />
            </div>
            <div className="flex items-center gap-4 mt-4">
              <Checkbox
                checked={user.faEnabled}
                sx={{ color: "rgb(var(--global-color-primary))" }}
              />
              <span className="text-white">Enable 2FA</span>
            </div>
            <div className="flex justify-end mt-8 gap-4">
              <StandardButton
                variant="outlined"
                text="Reset"
                width="200px"
                onClick={handleResetUser}
              />
              <StandardButton
                variant="filled"
                text="Save"
                width="200px"
                onClick={handleEditUser}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "@mui/material";
import StartupsCard from "../startupCard";

const UserBookMarks = () => {
  const [bookmarkedStartups, setBookmarkedStartups] = useState([]); // Dati delle startup
  const [loading, setLoading] = useState(true); // Stato di caricamento
  const [error, setError] = useState(null); // Stato di errore
  const [page, setPage] = useState(1); // Pagina corrente
  const [totalPages, setTotalPages] = useState(1); // Numero totale di pagine

  const perPage = 9; // Elementi per pagina
  const orderDir = "desc"; // Direzione di ordinamento
  const orderBy = "added"; // Campo di ordinamento

  // Funzione per recuperare le startup salvate
  const fetchBookmarkedStartups = async (page) => {
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Token not found. Please log in.");

      const queryParams = new URLSearchParams();
      queryParams.append("page", page);
      queryParams.append("per_page", perPage);
      queryParams.append("order_dir", orderDir);
      queryParams.append("order_by", orderBy);
      queryParams.append("bookmarked", "true");

      const url = `${process.env.REACT_APP_API_URL}/innovations?${queryParams.toString()}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = response.data;
      setBookmarkedStartups(data.data);
      setTotalPages(parseInt(data.last_page, 10));
    } catch (err) {
      setError(err.message || "Error loading data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookmarkedStartups(page);
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="flex flex-col gap-10">

      {/* Contenuto principale */}
      <div
        className="grid gap-3"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(1, 1fr)",
          gridGap: "15px",
        }}
      >
        <style>
          {`
            @media (min-width: 768px) {
              div.grid {
                grid-template-columns: repeat(3, 1fr) !important;
              }
            }
          `}
        </style>
        {loading ? (
          <p className="text-white">Loading bookmarked startups...</p>
        ) : error ? (
          <p style={{ color: "red" }}>{error}</p>
        ) : bookmarkedStartups.length > 0 ? (
          bookmarkedStartups.map((s) => (
            <StartupsCard
              key={s.id}
              id={s.id}
              name={s.name}
              description={
                s.description
                  ? s.description.replace(/<\/?[^>]+(>|$)/g, "")
                  : "No description available"
              }
              logo={
                s.logo_url ||
                `${process.env.REACT_APP_BASE_URL}/default-logo.png`
              }
              countryCode={s.country?.iso || "US"}
              tags={
                s.taxonomies?.tags?.length > 0
                  ? s.taxonomies.tags.map((tag, index) => ({
                      id: index,
                      label: tag.name || "N/A",
                      variant: "outlined",
                    }))
                  : [{ id: 0, label: "No tags", variant: "outlined" }]
              }
              link={`/startups/startup/${s.id}`}
              isBookmarked={s.is_bookmarked}
            />
          ))
        ) : (
          <p className="text-white">No bookmarked startups found.</p>
        )}
      </div>

      {/* Paginazione */}
      <div className="flex justify-center mt-6">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          className="pagination"
        />
      </div>
    </div>
  );
};

export default UserBookMarks;

import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Chip } from "@mui/material";
import axios from "axios";

const DialogTags1 = ({ open, handleClose, startupData, innovationId, onSave }) => {
    const [categories, setCategories] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
  
    useEffect(() => {
      const fetchCategories = async () => {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          if (!apiUrl) {
            console.error("API URL is not defined.");
            return;
          }
    
          const [taxonomyResponse, innovationResponse] = await Promise.all([
            axios.get(`${apiUrl}/taxonomies?type=category`),
            axios.get(`${apiUrl}/innovations/${innovationId}`)
          ]);
    
          const taxonomyData = taxonomyResponse.data.data;
          const innovationData = innovationResponse.data.data;        
    
          const secondLevelCategories = getFirstLevelCategories(taxonomyData);
          setCategories(secondLevelCategories);
    
          if (innovationData?.taxonomies?.categories) {
            const initialTags = innovationData.taxonomies.categories;
            setSelectedTags(initialTags);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } 
      };
    
      fetchCategories();
    }, [startupData, innovationId]);
  
    const getFirstLevelCategories = (nodes) => {
      return nodes.filter((node) => !node.parent_id);
    };
  
    const handleTagSelect = (tag) => {
      if (!selectedTags.some((t) => t.id === tag.id)) {
        setSelectedTags([...selectedTags, tag]);
      }
    };
  
    const handleTagRemove = (tagId) => {
      setSelectedTags(selectedTags.filter((tag) => tag.id !== tagId));
    };
  
    const handleSave = () => {
      // Invoca la callback `onSave` passando i tag selezionati
      onSave(selectedTags);
      handleClose(); // Chiudi il dialog
    };
  
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle
          sx={{
            fontWeight: "bold",
            backgroundColor: "#191919",
            color: "white",
            fontFamily: "Urbanist",
          }}
        >
          Sectors of Competence
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#191919", color: "white" }}>
          <div className="flex flex-wrap gap-2 mb-4">
            {selectedTags.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                onDelete={() => handleTagRemove(tag.id)}
                deleteIcon={
                  <span
                    style={{
                      color: "rgb(var(--global-color-primary))",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    &times;
                  </span>
                }
                sx={{
                  backgroundColor: "transparent",
                  border: "1.5px solid rgb(var(--global-color-primary))",
                  borderRadius: "5px",
                  fontFamily: "Urbanist",
                  color: "rgb(var(--global-color-primary))",
                  fontWeight: "bold",
                }}
              />
            ))}
          </div>
  
          <div className="flex flex-wrap gap-2">
            {categories.map((category) => (
              <Chip
                key={category.id}
                label={category.name}
                onClick={() => handleTagSelect({ id: category.id, name: category.name })}
                sx={{
                  backgroundColor: "white",
                  fontFamily: "Urbanist",
                  color: "black",
                  fontWeight: "bold",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "rgb(var(--global-color-primary))",
                    color: "black",
                  },
                }}
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#191919" }}>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "gray",
              color: "white",
              "&:hover": { backgroundColor: "rgba(128, 128, 128, 0.8)" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            sx={{
              backgroundColor: "rgb(var(--global-color-primary))",
              color: "black",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "rgba(var(--global-color-primary), 0.8)" },
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default DialogTags1;
  
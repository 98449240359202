import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import axios from "axios";

const AddMemberDialog = ({ open, handleClose, companyId }) => {
  // Puoi passare companyId come prop (es. 26). Se non lo hai, metti un default
  // Esempio: const [companyId, setCompanyId] = useState(26);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    department: "",
    position: "",
    email: "",
    password: "",
    phoneNumber: "",
    note: "",
  });

  const [positions, setPositions] = useState([]);
  const [departments, setDepartments] = useState([]);

  // ----------------------------------------
  // useEffect: recupera positions e departments
  // ----------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        // In alternativa potresti usare axios.get
        const [posResp, depResp] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/values/positions`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
          fetch(`${process.env.REACT_APP_API_URL}/values/departments`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
        ]);

        const positionsData = await posResp.json();
        const departmentsData = await depResp.json();

        setPositions(positionsData.data || []);
        setDepartments(departmentsData.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // ----------------------------------------
  // handleInputChange per gestire i cambi di input
  // ----------------------------------------
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // ----------------------------------------
  // handleAdd: POST verso /members
  // ----------------------------------------
  const handleAdd = async () => {
    try {
      const token = localStorage.getItem("token");

      // Assicurati di convertire position_id e department_id in numeri
      const payload = {
        company_id: companyId || 20,
        member_role_id: 1,
        position_id: formData.position ? parseInt(formData.position, 10) : null,
        department_id: formData.department
          ? parseInt(formData.department, 10)
          : null,
        email: formData.email,
        user_type_id: 1,
        password: formData.password,
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone_number: formData.phoneNumber || "",
        notes: formData.note === "" ? "nothing to say" : formData.note,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/members`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Member added successfully:", response.data);
      alert("Member added successfully!");
      handleClose();
    } catch (error) {
      console.error("Error adding member:", error);
      alert("Error adding member!");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          fontWeight: "bold",
          backgroundColor: "#191919",
          color: "white",
          textAlign: "center",
          fontFamily: "Urbanist",
        }}
      >
        Add a new member
      </DialogTitle>

      <DialogContent
        sx={{
          backgroundColor: "#191919",
          color: "white",
          padding: "24px",
          fontFamily: "Urbanist",
        }}
      >
        <Grid container spacing={3}>
          {/* First Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              name="firstName"
              label="First Name"
              fullWidth
              variant="outlined"
              value={formData.firstName}
              onChange={handleInputChange}
              InputProps={{ style: { color: "white", fontFamily: "Urbanist" } }}
              InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
            />
          </Grid>

          {/* Last Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              name="lastName"
              label="Last Name"
              fullWidth
              variant="outlined"
              value={formData.lastName}
              onChange={handleInputChange}
              InputProps={{ style: { color: "white", fontFamily: "Urbanist" } }}
              InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
            />
          </Grid>

          {/* Position */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel
                sx={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontFamily: "Urbanist",
                }}
              >
                Position
              </InputLabel>
              <Select
                name="position"
                value={formData.position}
                onChange={handleInputChange}
                sx={{ color: "white", fontFamily: "Urbanist" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      maxWidth: "100%",
                    },
                  },
                  disablePortal: true,
                }}
              >
                {positions.map((pos) => (
                  <MenuItem key={pos.id} value={pos.id}>
                    {pos.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Department */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel
                sx={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontFamily: "Urbanist",
                }}
              >
                Department
              </InputLabel>
              <Select
                name="department"
                value={formData.department}
                onChange={handleInputChange}
                sx={{ color: "white", fontFamily: "Urbanist" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                  disablePortal: true,
                }}
              >
                {departments.map((dep) => (
                  <MenuItem key={dep.id} value={dep.id}>
                    {dep.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Email & Password & Phone */}
          <Grid item xs={12} sm={4}>
            <TextField
              name="email"
              label="Email"
              fullWidth
              variant="outlined"
              value={formData.email}
              onChange={handleInputChange}
              InputProps={{ style: { color: "white", fontFamily: "Urbanist" } }}
              InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              name="password"
              label="Password"
              type="password"
              fullWidth
              variant="outlined"
              value={formData.password}
              onChange={handleInputChange}
              InputProps={{ style: { color: "white", fontFamily: "Urbanist" } }}
              InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              name="phoneNumber"
              label="Phone Number"
              fullWidth
              variant="outlined"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              InputProps={{ style: { color: "white", fontFamily: "Urbanist" } }}
              InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
            />
          </Grid>

          {/* Note */}
          <Grid item xs={12}>
            <TextField
              name="note"
              label="Note"
              multiline
              rows={3}
              fullWidth
              variant="outlined"
              value={formData.note}
              onChange={handleInputChange}
              InputProps={{ style: { color: "white", fontFamily: "Urbanist" } }}
              InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{
          backgroundColor: "#191919",
          padding: "16px",
          justifyContent: "center",
          fontFamily: "Urbanist",
        }}
      >
        <Button
          onClick={handleAdd}
          sx={{
            backgroundColor: "#A3E635",
            color: "#000",
            fontWeight: "bold",
            fontFamily: "Urbanist",
            "&:hover": { backgroundColor: "rgba(163, 230, 53, 0.8)" },
            padding: "8px 32px",
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMemberDialog;

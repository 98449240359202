import React, { useEffect, useState } from "react";
import { Chip, Fade, Slider, Button, Link, Tooltip } from "@mui/material";
import CountryFlag from "../countryFlag";
import EditIcon from '@mui/icons-material/Edit'; 
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthIcon from '@mui/icons-material/South';
import CompanyDialog from '../adminStartupDetails/dialogs/companyInformation';
import DescriptionDialog from '../adminStartupDetails/dialogs/descriptionCompany';
import DialogTags1 from '../adminStartupDetails/dialogs/dialogTags1';
import DialogTags2 from '../adminStartupDetails/dialogs/dialogTags2';
import FlexibleDataFetcher from "../flexibileDataFetcher";

const StartupDetails = ({ userData, userId, companyId }) => {  
  
  const [integrationTiming, setValue] = useState(3);
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const [readDescription, setReadMoreDescription] = useState(false);
  const [readServices, setServicesDescription] = useState(false);

  const [companyData, setCompanyData] = useState(null);

  const handleReadMoreDescription = () => {
    setReadMoreDescription(true);
  };
  const handleServicesDescription = () => {
    setServicesDescription(true);
  };

  const [openDialog, setOpenDialog] = useState(null);

  const handleOpenDialog = (dialogName) => setOpenDialog(dialogName);
  const handleCloseDialog = () => setOpenDialog(null);

  const handleDataFetchSuccess = (data) => {    
    if (data && data) {
      console.log("Dati ricevuti company: ", data);
      setCompanyData(data);
    } else {
      console.error("Formato dei dati non valido:", data);      
    }    
  };

  const handleDataFetchError = (data) => {
    console.log("data error");
  }


  if (!userId) {
    return <p>No data available.</p>;
  }


  return (
    <div className="flex flex-col gap-10 pt-3">      

      {/* Dati principali */}
      <div className="grid grid-cols-2 gap-8">

        <div className="flex flex-col gap-10">
        
            {/* Company */}

            <div className="flex flex-col gap-4 no-p-margin">

                <div className="flex flex-row items-center gap-2 ">
                    <h2 className="text-4xl font-semibold m-0">Company Information</h2>
                    <EditIcon
                        sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                        onClick={() => handleOpenDialog("dialog1")}
                    />
                </div>

                <p><strong>Phone:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {companyData?.phone_number ? userData.phone_number.replaceAll(';', '') : "N/A"} </span></p>
                <p><strong>City:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {companyData?.city || "N/A"} </span></p>
                <p><strong>Legal Name:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> NON ESISTE </span></p>
                <p><strong>Brand Name:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> NON ESISTE </span></p>
                <p><strong>LinkedIn:</strong> <a className="text-[rgb(var(--global-color-primary))]" href={companyData?.linkedin_url || "#"} target="_blank"> {companyData?.linkedin_url || "NON ESISTE"} </a></p>
                <p><strong>Website:</strong> <a className="text-[rgb(var(--global-color-primary))]" href={companyData?.website || "#"} target="_blank"> {companyData?.website || "NON ESISTE"} </a></p>
                <p>
                  <strong>Companies:</strong>{" "}
                  <span className="text-[rgb(var(--global-color-primary))] capitalize">
                    {userData.target_markets?.length > 0
                      ? userData.target_markets.map((market) => market.name).join(", ")
                      : "NON ESISTE"}
                  </span>
                </p>
                <p><strong>Individuals:</strong> <a className="text-[rgb(var(--global-color-primary))] capitalize" href={userData?.linkedin_url || "#"} target="_blank"> {userData?.linkedin_url || "NON ESISTE"} </a></p>
            </div>

        </div>

        

        <div className="flex flex-col gap-10">
            
            {/* Description */}

            <div className="flex flex-col gap-4 no-p-margin">
                <div className="flex flex-row items-center gap-2 ">
                    <h2 className="text-4xl font-semibold m-0">Description</h2>
                    <EditIcon
                        sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                        onClick={() => handleOpenDialog("dialog2")}
                    />
                </div>
                <p 
                  className={readDescription ? "h-full overflow-auto" : " max-h-40 overflow-hidden"}
                  dangerouslySetInnerHTML={{ __html: userData.description || "NON ESISTE" }}
                />
                {
                  !readDescription &&
                  <div className="flex gap-2 items-center hover:no-underline cursor-pointer text-[rgb(var(--global-color-primary))]" onClick={handleReadMoreDescription}>
                    <span>Read more</span>
                    <SouthIcon sx={{color: "rgb(var(--global-color-primary))"}}/>
                  </div>
                }
            </div>
            
            {/* Sectors */}

            <div className="flex flex-col gap-4 no-p-margin">
                <div className="flex flex-row items-center gap-2 ">
                    <h2 className="text-4xl font-semibold m-0">Sectors of Competence</h2>
                    <EditIcon
                        sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                        onClick={() => handleOpenDialog("dialog3")}
                    />
                </div>
                <div className="flex flex-row items-center gap-2 ">
                      {companyData?.categories?.length > 0 ? (
                      companyData?.categories?.map((category) => (
                        <div key={category.id} className="flex gap-2 justify-start items-center cursor-pointer">
                          <Tooltip
                            title={
                              category.children?.length > 0
                                ? (
                                  <div>
                                    {category.children.map((child) => (
                                      <div key={child.id}>
                                        {child.name}
                                        {child.children?.length > 0 && (
                                          <div style={{ marginLeft: '15px' }}>
                                            {child.children.map((grandChild) => (
                                              <div key={grandChild.id}>
                                                {grandChild.name}
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                )
                                : "No children available"
                            }
                            arrow
                            placement="right"
                          >
                            <img src={category.logo || "N/A"} style={{width:"50px"}}></img>
                          </Tooltip>
                        </div>
                      ))
                    ) : (
                      <span>No sectors available</span>
                    )}
              </div>
            </div>

            {/* Tags */}

            <div className="flex flex-col gap-4">
              <div className="flex flex-row items-center gap-2 ">
                <h2 className="text-4xl font-semibold m-0">Tags</h2> 
                    <EditIcon
                        sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                        onClick={() => handleOpenDialog("dialog4")}
                    />
              </div>
              <div className="flex flex-wrap gap-2">
                {companyData?.tags?.length > 0 ? (
                  companyData?.tags?.map((tag) => (
                    <Chip
                      key={tag.id}
                      label={tag.name}
                      sx={{
                        fontFamily: "Urbanist, sans-serif",
                        backgroundColor: "#fff",
                        color: "#191919",
                        border: "1px solid rgb(255, 255, 255, .3)",
                        fontWeight: "500",
                        ":hover": {
                            backgroundColor: 'rgb(var(--global-color-primary))',
                            cursor: "pointer"
                        }
                      }}
                    />
                  ))
                ) : (
                  <span>No tags available</span>
                )}
              </div>
            </div>

            </div>
      </div>

      {/* Company Dialog */}
      <CompanyDialog 
        open={openDialog === "dialog1"}
        handleClose={handleCloseDialog}
        innovationId={userData.id}         
      />

      {/* Description Dialog */}
      <DescriptionDialog 
        open={openDialog === "dialog2"}
        handleClose={handleCloseDialog}
        innovationId={userData.id}         
      />

      {/* Tags Dialog */}
      <DialogTags1 
        open={openDialog === "dialog3"}
        handleClose={handleCloseDialog}     
        userData={userData}
        innovationId={userData.id} 
      />

      <DialogTags2
        open={openDialog === "dialog4"}
        handleClose={handleCloseDialog}     
        userData={companyData}
        innovationId={userData.id} 
      />

      <FlexibleDataFetcher
        endpoint={`companies/${companyId}`}
        onSuccess={handleDataFetchSuccess}
        onError={handleDataFetchError}
      />

    </div>

    
  );
};

export default StartupDetails;

import React, { useState } from "react";
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Alert,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Checkbox,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Creazione del tema personalizzato con font Urbanist
const theme = createTheme({
    typography: {
        fontFamily: "Urbanist, Arial, sans-serif",
    },
    palette: {
        primary: {
            main: "#c5ff55", // Colore personalizzato per i bottoni
        },
    },
});

export default function Register() {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        companyType: "Retailer", // Valore di default
        companyName: "",
        agreeToTerms: false,
    });
    const [error, setError] = useState("");

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
    };

    const validatePassword = () => {
        const { password, confirmPassword } = formData;
        const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
    
        if (!passwordRegex.test(password)) {
            setError(
                "The password must contain at least one special character, one number, and be at least 6 characters long."
            );
            return false;
        }
        if (password !== confirmPassword) {
            setError("Password doesn't match.");
            return false;
        }
        setError("");
        return true;
    };
    

    const nextStep = () => {
        if (step === 1) {
            // Controlla email e password
            if (!formData.email.trim()) {
                setError("Please enter a valid email.");
                return;
            }
            if (!validatePassword()) return;
        }

        if (step === 2) {
            // Controlla se Name e Last Name sono stati compilati
            if (!formData.firstName.trim() || !formData.lastName.trim()) {
                setError("Please fill in both Name and Last Name.");
                return;
            }
            if (!formData.agreeToTerms) {
                setError("You must agree to the terms to proceed.");
                return;
            }
        }

        setError(""); // Rimuovi eventuali errori precedenti
        setStep((prev) => prev + 1);
    };

    const prevStep = () => setStep((prev) => prev - 1);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form data submitted:", formData);
        alert("Registration completed!");
    };

    return (
        <ThemeProvider theme={theme}>
            <Container
                maxWidth="sm"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100vh",
                    alignItems: "center",
                }}
            >
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        width: "100%",
                        padding: 3,
                        backgroundColor: "#191919", // Sfondo personalizzato
                        borderRadius: 2,
                        boxShadow: 3,
                        color: "#fff",
                    }}
                >
                    {step === 1 && (
                        <Box>
                            <Typography variant="h5" gutterBottom>
                                Email and Password
                            </Typography>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                type="email"
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                                sx={{
                                    input: { color: "#fff" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Password"
                                name="password"
                                type="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                                sx={{
                                    input: { color: "#fff" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Confirm Password"
                                name="confirmPassword"
                                type="password"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                                sx={{
                                    input: { color: "#fff" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                    },
                                }}
                            />
                            {error && (
                                <Alert severity="error" sx={{ marginTop: 2 }}>
                                    {error}
                                </Alert>
                            )}
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ marginTop: 2, backgroundColor: "#c5ff55", color: "#191919" }}
                                onClick={nextStep}
                            >
                                Next
                            </Button>
                        </Box>
                    )}
                    {step === 2 && (
                        <Box>
                            <Typography variant="h5" gutterBottom>
                                Personal Details
                            </Typography>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="First Name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                                sx={{
                                    input: { color: "#fff" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Last Name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                                sx={{
                                    input: { color: "#fff" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                    },
                                }}
                            />
                            <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
                                <Box>
                                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                        Companies:
                                    </Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            name="companyType"
                                            value={formData.companyType}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel
                                                value="Retailer"
                                                control={<Radio sx={{ color: "#c5ff55" }} />}
                                                label="Retailer"
                                            />
                                            <FormControlLabel
                                                value="Brand"
                                                control={<Radio sx={{ color: "#c5ff55" }} />}
                                                label="Brand"
                                            />
                                            <FormControlLabel
                                                value="Consultant"
                                                control={<Radio sx={{ color: "#c5ff55" }} />}
                                                label="Consultant"
                                            />
                                            <FormControlLabel
                                                value="Service provider"
                                                control={<Radio sx={{ color: "#c5ff55" }} />}
                                                label="Service Provider"
                                            />
                                            <FormControlLabel
                                                value="Venture capital"
                                                control={<Radio sx={{ color: "#c5ff55" }} />}
                                                label="Venture Capital"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                        Individuals:
                                    </Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            name="companyType"
                                            value={formData.companyType}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel
                                                value="Entrepreneur"
                                                control={<Radio sx={{ color: "#c5ff55" }} />}
                                                label="Entrepreneur"
                                            />
                                            <FormControlLabel
                                                value="Investor"
                                                control={<Radio sx={{ color: "#c5ff55" }} />}
                                                label="Investor"
                                            />
                                            <FormControlLabel
                                                value="Private person"
                                                control={<Radio sx={{ color: "#c5ff55" }} />}
                                                label="Private Person"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Box>

                            <TextField
                                fullWidth
                                margin="normal"
                                label="Company Name"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                                sx={{
                                    input: { color: "#fff" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#c5ff55",
                                        },
                                    },
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="agreeToTerms"
                                        checked={formData.agreeToTerms}
                                        onChange={handleChange}
                                        sx={{ color: "#c5ff55" }}
                                    />
                                }
                                label="I agree to the RetailHub's Terms of services, Privacy Policy and Security Policy, including Cookie Use Policy."
                                sx={{ color: "#fff", marginTop: 2 }}
                            />
                            {error && (
                                <Alert severity="error" sx={{ marginTop: 2 }}>
                                    {error}
                                </Alert>
                            )}
                            <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
                                <Button
                                    variant="outlined"
                                    sx={{ borderColor: "#c5ff55", color: "#c5ff55" }}
                                    onClick={prevStep}
                                >
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ backgroundColor: "#c5ff55", color: "#191919" }}
                                >
                                    Confirm
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Container>
        </ThemeProvider>
    );
}

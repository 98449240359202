import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import di useNavigate
import PaginatedTable from '../components/paginatedTable';

import { Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Pagination, Select, TextField } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
// import NewUserDialog from "../components/newUserDialog";
import axios from "axios";
import ConfirmDialog from '../components/confirmDialog';
import StandardButton from "../components/standardButton";
import { useLocation } from "react-router-dom";
import { useDebounce } from 'use-debounce';

function LogoColumn(params) {
  if (!!params.value)
    return (
      <div className="flex flex-col h-full items-center justify-center">
        <img src={params.value} className="w-8 h-8 object-cover rounded-full" alt="Logo" />
      </div>
    );
  else
    return (
      <div className="flex flex-col h-full items-center justify-center">
        <div className="w-8 h-8 object-cover rounded-full bg-[rgb(var(--global-color-primary))] flex items-center justify-center text-black">L</div>
      </div>
    );
}

function StatusColumn(params) {
  return <span>{params.value ? 'Blocked' : 'Active'}</span>;
}

export default function UserList() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [userEmailToDelete, setUserEmailToDelete] = useState("");
  const navigate = useNavigate(); // Inizializzazione di useNavigate
  const [perPage] = useState(15);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  const location = useLocation();
  const header = [
    { field: 'id', headerName: 'Id', width: 70 },
    {
      field: 'company_logo',
      headerName: 'Logo',
      width: 50,
      type: 'custom',
      renderCell: LogoColumn,
    },
    { field: 'company_name', headerName: 'Brand', width: 200 },
    { field: 'first_name', headerName: 'Firstname', width: 130 },
    { field: 'last_name', headerName: 'Lastname', width: 130 },
    { field: 'email', headerName: 'Email', width: 220 },
    {
      field: 'is_verified',
      headerName: 'Email verified',
      type: 'boolean',
      width: 120,
    },
    {
      field: 'is_blocked',
      headerName: 'Status',
      type: 'custom',
      width: 120,
      renderCell: StatusColumn,
    },
    {
      field: 'last_seen_at',
      headerName: 'Last Seen',
      width: 200,
      renderCell: (params) => (
        <span>{params.value ? new Date(params.value).toLocaleString() : "Never"}</span>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id, row.company.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleOpenConfirmDialog(id, row.email)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  // Funzione per convertire i parametri della query string in un oggetto
  const parseQueryParams = (search) => {
    const params = new URLSearchParams(search);
    const parsedParams = {};
    for (const [key, value] of params.entries()) {
      parsedParams[key] = value;
    }
    return parsedParams;
  };

  const [filters, setFilters] = useState({}); // Dati per i filtri dall'API
  const [selectedFilters, setSelectedFilters] = useState({
    email_verified: "",
    approved: "",
    account_status: "",
    registration: "",
    activity: "",
    expiration: "",
    order_by: "",
    order_dir: "",
    user_type: 0,
    plan: 0,
    country: 0,
  });
  const [orderDir, setOrderDir] = useState("desc"); // Aggiunto stato per `order_dir`
  const [orderBy] = useState("added");

  const handleEditClick = (id, company_id) => () => {
    navigate(`/user/${id}`, { state: { companyId: company_id } });
  };

  const handleDeleteUser = async (id, email) => {
    try {
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_URL}/users/${id}`;
  
      await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          email, // Passa l’email nel body
        },
      });
  
      // Ricarica la lista se serve
      fetchUsers(page);
    } catch (err) {
      console.error("Error deleting user:", err);
    } finally {
      setOpenConfirmDialog(false);
      setUserIdToDelete(null);
      setUserEmailToDelete("");
    }
  };
  

  const handleOpenConfirmDialog = (id, email) => () => {
    setUserIdToDelete(id);
    setUserEmailToDelete(email);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setUserIdToDelete(null);
  };

  // Funzione per ottenere i dati dei filtri
  const fetchFilterData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/values/users-filters`, {
        params: {
          admin: true
        }
      });
      setFilters(response.data.data);
    } catch (err) {
      console.error("Errore nel caricamento dei filtri", err);
    }
  };

  const fetchUsers = async (page) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
      const queryParams = new URLSearchParams();

      queryParams.append('page', page);
      queryParams.append('per_page', perPage);
      queryParams.append('order_dir', orderDir); // Usa `orderDir` dallo stato

      // Aggiungi i filtri ai parametri della query string
      
      if (selectedFilters.email_verified !== "") {
        queryParams.append('email_verified', selectedFilters.email_verified);
      }

      if (selectedFilters.approved !== "") {
        queryParams.append('approved', selectedFilters.approved);
      }

      if (selectedFilters.account_status !== "") {
        queryParams.append('status', selectedFilters.account_status);
      }

      if (selectedFilters.registration !== "") {
        queryParams.append('registration', Number(selectedFilters.registration));
      }

      if (selectedFilters.activity !== "") {
        queryParams.append('activity', Number(selectedFilters.activity));
      }

      if (selectedFilters.expiration !== "") {
        queryParams.append('expiration', Number(selectedFilters.expiration));
      }

      if (selectedFilters.order_by !== "") {
        queryParams.append('order_by', selectedFilters.order_by);
      }

      if (selectedFilters.user_type !== 0) {
        queryParams.append('user_type', selectedFilters.user_type);
      }
      if (selectedFilters.plan !== 0) {
        queryParams.append('plan', selectedFilters.plan);
      }

      if (selectedFilters.country !== 0) {
        queryParams.append('country', selectedFilters.country);
      }

      if (debouncedSearchTerm.trim() !== "") {
        queryParams.append("search", debouncedSearchTerm.trim());
      }

      // Aggiungi i parametri della query string dall'URL
      const queryParamsFromURL = parseQueryParams(location.search);
      Object.entries(queryParamsFromURL).forEach(([key, value]) => {
        if (!queryParams.has(key)) {
          queryParams.append(key, value);
        }
      });

      // const url = `${process.env.REACT_APP_API_URL}/users?page=${page}&per_page=${perPage}`;
      const url = `${process.env.REACT_APP_API_URL}/users?${queryParams.toString()}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      handleUsersList(response.data);
      setTotalPages(parseInt(response.data.last_page, 10));
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
      if (debouncedSearchTerm.trim() !== "") {
        fetchUsers(1);
      } else {
        fetchUsers(page);
      }
    }, [debouncedSearchTerm, page]);
  

  const handleUsersList = (data) => {
    setLoading(true)
    if(data && data.data) {
      let filteredUsers = []
      for (let i = 0; i < data.data.length; i++) {
        if(!filteredUsers.find(s => s.id === data.data[i].id)) {
          filteredUsers.push({
            ...data.data[i],
            company_name: data.data[i].company?.name || "",
            last_seen_at: data.data[i].last_seen_at || null,
        })
        }
      }
      setUsers(filteredUsers)
    }
    setLoading(false)
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  
  // Funzione per resettare i filtri
  const resetFilters = () => {
    setSelectedFilters({
      email_verified: "",
      approved: "",
      account_status: "",
      registration: "",
      activity: "",
      expiration: "",
      order_by: "",
      order_dir: "",
      user_type: 0,
      plan: 0,
      country: 0,
    });
    setOrderDir("desc"); // Resetta anche `orderDir`
    setPage(1);
  };
  
  const handleFilterChange = (filterName, selectedValues) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterName]: selectedValues
    }));
  };
  
  const handleOrderChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "reset") {
      resetFilters(); // Resetta tutti i filtri
    } else {
      setOrderDir(selectedValue); // Aggiorna solo `orderDir`
    }
  };

  useEffect(() => {
    fetchUsers(page);
  }, [page, selectedFilters, orderDir, location.search]);

  useEffect(() => {
    fetchFilterData();
  }, []);

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-[100vh]">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full relative">
          
          <div className="flex justify-between items-center w-full">
            <h1 className="text-6xl font-medium">User List</h1>
            <StandardButton text={"Add new user"} width={"300px"} />
          </div>

          <div className="flex flex-wrap gap-4">

            <FilterSelect
              label="Email verified"
              options={[{ id: "", name: "Reset" }, ...(filters.email_verified?.map(s => {return {id: s.value, name: s.label}}) || [])]}
              selected={selectedFilters.email_verified}
              multiple={false}
              onChange={(selected) => handleFilterChange('email_verified', selected)}
            />

            <FilterSelect
              label="Approved"
              options={[{ id: "", name: "Reset" }, ...(filters.approved?.map(s => {return {id: s.value, name: s.label}}) || [])]}
              selected={selectedFilters.approved}
              multiple={false}
              onChange={(selected) => handleFilterChange('approved', selected)}
              width={"170px"}
            />

            <FilterSelect
              label="Account status"
              options={[{ id: "", name: "Reset" }, ...(filters.account_status?.map(s => {return {id: s.value, name: s.label}}) || [])]}
              selected={selectedFilters.account_status}
              multiple={false}
              onChange={(selected) => handleFilterChange('account_status', selected)}
              width={"170px"}
            />

            <FilterSelect
              label="Registration"
              options={[{ id: "", name: "Reset" }, ...(filters.registration?.map(s => {return {id: s.value, name: s.label}}) || [])]}
              selected={selectedFilters.registration}
              multiple={false}
              onChange={(selected) => handleFilterChange('registration', selected)}
            />

            <FilterSelect
              label="Activity"
              options={[{ id: "", name: "Reset" }, ...(filters.activity?.map(s => {return {id: s.value, name: s.label}}) || [])]}
              selected={selectedFilters.activity}
              multiple={false}
              onChange={(selected) => handleFilterChange('activity', selected)}
            />

            <FilterSelect
              label="Expiration"
              options={[{ id: "", name: "Reset" }, ...(filters.expiration?.map(s => {return {id: s.value, name: s.label}}) || [])]}
              selected={selectedFilters.expiration}
              multiple={false}
              onChange={(selected) => handleFilterChange('expiration', selected)}
              width={"150px"}
            />

            <FilterSelect
              label="Order by"
              options={[{ id: "", name: "Reset" }, ...(filters.order_by?.map(s => {return {id: s.value, name: s.label}}) || [])]}
              selected={selectedFilters.order_by}
              multiple={false}
              onChange={(selected) => handleFilterChange('order_by', selected)}
              width={"120px"}
            />
            
            <FilterSelect
              label="User type"
              options={[{ id: "", name: "Reset" }, ...(filters.user_type?.map(s => {return {id: s.id, name: s.name}}) || [])]}
              selected={selectedFilters.user_type}
              multiple={false}
              onChange={(selected) => handleFilterChange('user_type', selected)}
              width={"120px"}
            />
            
            <FilterSelect
              label="Plan"
              options={[{ id: "", name: "Reset" }, ...(filters.plan?.map(s => {return {id: s.id, name: s.name}}) || [])]}
              selected={selectedFilters.plan}
              multiple={false}
              onChange={(selected) => handleFilterChange('plan', selected)}
              width={"120px"}
            />
            
            <FilterSelect
              label="Country"
              options={[{ id: "", name: "Reset" }, ...(filters.country?.map(s => {return {id: s.id, name: s.name}}) || [])]}
              selected={selectedFilters.country}
              multiple={false}
              onChange={(selected) => handleFilterChange('country', selected)}
              width={"120px"}
            />

            <FormControl sx={{ minWidth: 120 }}>
              <Select value={orderDir} onChange={handleOrderChange}>
                <MenuItem value="asc">Older</MenuItem>
                <MenuItem value="desc">Recent</MenuItem>
                <MenuItem value="reset">Reset</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Search"
              variant="outlined"
              sx={{
                input: { color: "white" },
                "& label": { color: "rgb(255, 255, 255, .7)" },
                "& label.Mui-focused": { color: "rgb(255, 255, 255)" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "rgb(255, 255, 255, .3)" },
                  "&:hover fieldset": { borderColor: "rgb(255, 255, 255, .7)" },
                  "&.Mui-focused fieldset": { borderColor: "rgb(255, 255, 255)" },
                },
              }}
              onChange={(e) => setSearchTerm(e.target.value)} // Aggiorna il valore del campo di ricerca
            />


            <Button variant="outlined" onClick={resetFilters} sx={{ ...buttonStyles }}>
              Reset All Filters
            </Button>
          </div>
          {
            !loading &&
            <PaginatedTable header={header} items={users} hideFooterPagination />
          }
          {
            loading &&
            <div>Loading users...</div>
          }
          <div className="flex justify-center mt-6">
            <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
          </div>
        </div>
      </div>
      {openConfirmDialog && (
  <ConfirmDialog
    open={openConfirmDialog}
    handleClose={handleCloseConfirmDialog}
    id={userIdToDelete}              // Passi l’ID
    email={userEmailToDelete}        // Passi l’email
    handleConfirm={handleDeleteUser} // Passi la callback per DELETE
    title="Confirm Delete"
    message="Are you sure you want to delete this user?"
  />
)}
    </div>
  );
}

const FilterSelect = ({ label, options, selected, onChange, multiple = true, width }) => {
  return (
    <FormControl sx={{ minWidth: 120, width: !!width ? width : 'auto' }}>
      <InputLabel sx={{ fontFamily: 'Urbanist, sans-serif' }}>{label}</InputLabel>
      <Select
        sx={{
          border: !!selected ? '1px solid rgb(var(--global-color-primary))' : '1px solid white' ,
          '.MuiOutlinedInput-notchedOutline' : {
            border: '0'
          },
          '.MuiFormLabel-root .MuiInputLabel-root' : {
            backgroundColor: '#191919'
          },
          '.Mui-focused' : {
            border: '0 !important'
          },
        }}
        multiple={multiple}
        value={selected || (multiple ? [] : "")}
        onChange={(e) => onChange(e.target.value)}
        renderValue={(selected) => {
          if (multiple) {
            return selected
              .map((sel) => options.find((option) => option.id === sel)?.name || "")
              .join(', ');
          } else {
            return options.find((option) => option.id === selected)?.name || "";
          }
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {multiple && <Checkbox checked={selected.indexOf(option.id) > -1} />}
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const buttonStyles = {
  fontFamily: "Urbanist, sans-serif",
  border: "1px solid rgb(255, 255, 255, .3)",
  color: "rgb(255, 255, 255, .3)"
};
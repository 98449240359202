import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar, Switch, Button, FormControlLabel } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { styled } from "@mui/material/styles";
import axios from "axios";
import AddMemberDialog from "../adminStartupDetails/dialogs/addCompanyMember";
import EditMemberDialog from "../adminStartupDetails/dialogs/editCompanyMembers";

const CompanyMember = ({ companyId }) => {
  const [users, setUsers] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/companies/${companyId}`
        );
        setUsers(response.data.members || []);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [companyId]);

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#c5ff55",
          opacity: 1,
          border: 0,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
      backgroundColor: "#191919",
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleOpenEditDialog = (user) => {
    setSelectedUser(user);
    setEditDialogOpen(true);
    console.log(user);
  };
  
  const handleCloseEditDialog = () => {
    setSelectedUser(null);
    setEditDialogOpen(false);
  };
  

  return (
    <>
      <div className="p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 h-full">
        {users.map((user) => (
          <Box
            key={user.id}
            className="p-4 rounded-lg shadow-lg bg-transparent text-white urbanist flex flex-col h-[300px]"
            sx={{
              border: "1px solid #C5FF55",
              fontFamily: "Urbanist",
            }}
          >
            <div className="flex flex-row mb-2">
              <div className="flex w-1/3">
                <Avatar alt={user.first_name} src={user.avatar || ""} />
              </div>
              <div className="flex flex-col w-2/3 gap-3">
                <h2 className="text-2xl m-0">{user.first_name} {user.last_name}</h2>
                <FormControlLabel
                  sx={{ gap: "5px", margin: "0" }}
                  control={
                    <IOSSwitch
                      checked={!user.is_blocked}
                      inputProps={{ "aria-label": "Account Status" }}
                      className="text-red-400"
                    />
                  }
                  label="Account Status"
                  className="text-white text-sm"
                />
              </div>
            </div>
            <div className="flex-1">
              <p className="text-base mb-1 urbanist">
                <strong>Job:</strong>{" "}
                {user.member_details.position?.name || "-"}
              </p>
              <p className="text-base mb-1 urbanist">
                <strong>Department:</strong>{" "}
                {user.member_details.department?.name || "-"}
              </p>
              <p className="text-base mb-1 urbanist">
                <strong>Email:</strong> {user.email}
              </p>
              <p className="text-base mb-1 urbanist">
                <strong>Location:</strong> {user.country?.iso3 || ""},{" "}
                {user.city || ""}
              </p>            
            </div>
            <div className="mt-auto flex flex-row justify-between items-center">
              <p className="text-base text-gray-400 italic m-0 urbanist">
                Last seen on:{" "}
                {user.last_seen_at
                  ? new Date(user.last_seen_at).toLocaleDateString("it-IT", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                  : "Never been active"}
              </p>
              <Button
                variant="contained"
                size="small"
                className="urbanist"
                sx={{
                  backgroundColor: "#C5FF55",
                  color: "#191919",
                  width: "150px",
                  height: "40px"
                }}
                onClick={() => handleOpenEditDialog(user)}
              >
                Edit
              </Button>
            </div>
          </Box>
        ))}

        {/* Add New Member Card */}
        <Box
          className="p-4 flex flex-col items-center justify-center rounded-lg shadow-lg bg-transparent text-white cursor-pointer h-[300px] min-h-full"
          sx={{
            border: "1px solid #C5FF55",
            fontFamily: "Urbanist",
          }}
          onClick={handleOpenDialog} // Apre il dialog al click
        >
          <AddCircleOutlineIcon style={{ fontSize: 48, color: "#A3E635" }} />
          <h2 className="text-2xl m-3">Add New</h2>
        </Box>
      </div>

      {/* Dialog di Aggiunta */}
      <AddMemberDialog open={dialogOpen} companyId={companyId} handleClose={handleCloseDialog} />
      <EditMemberDialog
        open={editDialogOpen}
        userId={selectedUser?.id}
        handleClose={handleCloseEditDialog}
      />
    </>
  );
};

export default CompanyMember;

import React from "react";
import PaginatedTable from '../components/paginatedTable'

import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

const items = [
  { id: 1, clientName: 'Single Investor', brand: 'Jon Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 2, clientName: 'Single Investor', brand: 'Cersei Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 3, clientName: 'Single Investor', brand: 'Jaime Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 4, clientName: 'Single Investor', brand: 'Arya Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 5, clientName: 'Single Investor', brand: 'Daenerys Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 6, clientName: 'Single Investor', brand: 'Camille Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 7, clientName: 'Single Investor', brand: 'Ferrara Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 8, clientName: 'Single Investor', brand: 'Rossini Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 9, clientName: 'Single Investor', brand: 'Harvey Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 10, clientName: 'Single Investor', brand: 'Jon Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 11, clientName: 'Single Investor', brand: 'Cersei Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 12, clientName: 'Single Investor', brand: 'Jaime Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 13, clientName: 'Single Investor', brand: 'Arya Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 14, clientName: 'Single Investor', brand: 'Daenerys Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 15, clientName: 'Single Investor', brand: 'Camille Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 16, clientName: 'Single Investor', brand: 'Ferrara Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 17, clientName: 'Single Investor', brand: 'Rossini Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
  { id: 18, clientName: 'Single Investor', brand: 'Harvey Jones', plan: 'Free', reference: "Admin", amount: 100, date: '10 Oct 2023, 10:00 a.m.', method: 'Standard' },
];

export default function PaymentReceipts() {
  const [rows, setRows] = React.useState(items);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const header = [
    { field: 'clientName', headerName: 'Client Name', width: 200 },
    { field: 'brand', headerName: 'Brand', width: 200 },
    { field: 'plan', headerName: 'Plan', width: 130 },
    { field: 'reference', headerName: 'Reference', width: 130 },
    { field: 'amount', headerName: 'Amount', type: "number", width: 130 },
    { field: 'date', headerName: 'Created Date', width: 200 },
    { field: 'method', headerName: 'Method', width: 130 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
  
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    }
  ];

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (!!editedRow && editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-[100vh]">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full relative">
          
          <h1 className="text-6xl font-medium">Payment Receipts</h1>
          <PaginatedTable header={header} items={items} />
        </div>
      </div>
    </div>
  )
}